import InputDate from "src/components/core/InputDate";
import React from "react";
import { useTranslation } from "src/locales/i18n";

interface InsurancePlanInputDateProps {
  labelTranslationKey: string;
  inputProps: {
    errorMsg: JSX.Element | undefined;
    name: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    value: unknown;
  };
  value: string | undefined;
}

const InsurancePlanInputDate: React.FC<InsurancePlanInputDateProps> = ({
  labelTranslationKey,
  inputProps,
  value,
}) => {
  const { t } = useTranslation();

  return (
    <InputDate
      {...inputProps}
      smallLabel
      label={t(
        `pages.employersExemptionsInsuranceDetails.${labelTranslationKey}`
      )}
      dayLabel={t("components.form.dateInputDayLabel")}
      monthLabel={t("components.form.dateInputMonthLabel")}
      yearLabel={t("components.form.dateInputYearLabel")}
      value={value}
      example={t("components.form.dateInputExample")}
    />
  );
};

export default InsurancePlanInputDate;
