import withEmployerExemptionsApplication, {
  WithEmployerExemptionsApplicationProps,
} from "src/hoc/withEmployerExemptionsApplication";

import FormLabel from "src/components/core/FormLabel";
import InputText from "src/components/core/InputText";
import PageNotFound from "src/components/PageNotFound";
import QuestionPage from "src/components/QuestionPage";
import React from "react";
import { Trans } from "react-i18next";
import { isFeatureEnabled } from "src/services/featureFlags";
import useFormState from "src/hooks/useFormState";
import useFunctionalInputProps from "src/hooks/useFunctionalInputProps";
import { useTranslation } from "src/locales/i18n";

/* 
prefix each field 'exemptionRequest.' this is needed for the employer 
exemptions progress page to display correctly
*/
export const fields = [
  "exemptionRequest.tpa_contact_first_name",
  "exemptionRequest.tpa_contact_last_name",
  "exemptionRequest.tpa_contact_title",
  "exemptionRequest.tpa_contact_phone.phone_number",
  "exemptionRequest.tpa_email_address",
];

const translationPrefix =
  "pages.employersExemptionsThirdPartyAdministratorContactDetails";

export const EmployerExemptionsThirdPartyAdministratorContactDetails = (
  props: WithEmployerExemptionsApplicationProps
) => {
  const { appLogic, exemptionRequest, user } = props;
  const { t } = useTranslation();
  const { user_leave_administrators } = user;

  const { formState, updateFields } = useFormState({
    tpa_contact_first_name: exemptionRequest.tpa_contact_first_name,
    tpa_contact_last_name: exemptionRequest.tpa_contact_last_name,
    tpa_contact_title: exemptionRequest.tpa_contact_title,
    tpa_contact_phone: {
      phone_number: exemptionRequest.tpa_contact_phone?.phone_number,
    },
    tpa_email_address: exemptionRequest.tpa_email_address,
  });

  // TODO (PFMLPB-19806): removal of feature flag
  const isEnableEmployerExemptionsPortal = isFeatureEnabled(
    "enableEmployerExemptionsPortal"
  );

  const handleSave = async () => {
    await appLogic.employerExemptionsApplication.update(
      exemptionRequest.employer_exemption_application_id,
      formState
    );
  };

  const getFunctionalInputProps = useFunctionalInputProps({
    errors: appLogic.errors,
    formState,
    updateFields,
  });

  if (!isEnableEmployerExemptionsPortal) {
    return <PageNotFound />;
  }

  return (
    <React.Fragment>
      <QuestionPage
        title={t(`${translationPrefix}.title`)}
        headingPrefix={
          <Trans
            i18nKey="shared.employerExemptions.employerEIN"
            values={{
              employer_fein: user_leave_administrators[0].employer_fein,
            }}
          />
        }
        onSave={handleSave}
      >
        <FormLabel
          component="legend"
          hint={t(`${translationPrefix}.sectionLabelHint`)}
        >
          {t(`${translationPrefix}.sectionLabel`)}
        </FormLabel>
        <InputText
          {...getFunctionalInputProps("tpa_contact_first_name")}
          label={t(`${translationPrefix}.tpa_contact_first_name`)}
          smallLabel={true}
        />
        <InputText
          {...getFunctionalInputProps("tpa_contact_last_name")}
          label={t(`${translationPrefix}.tpa_contact_last_name`)}
          smallLabel={true}
        />
        <InputText
          {...getFunctionalInputProps("tpa_contact_title")}
          label={t(`${translationPrefix}.tpa_contact_title`)}
          smallLabel={true}
        />
        <InputText
          {...getFunctionalInputProps("tpa_contact_phone.phone_number")}
          label={t(`${translationPrefix}.tpa_contact_phone`)}
          smallLabel={true}
          mask="phone"
          pii={true}
        />
        <InputText
          {...getFunctionalInputProps("tpa_email_address")}
          label={t(`${translationPrefix}.tpa_contact_email_address`)}
          smallLabel={true}
        />
      </QuestionPage>
    </React.Fragment>
  );
};

export default withEmployerExemptionsApplication(
  EmployerExemptionsThirdPartyAdministratorContactDetails
);
