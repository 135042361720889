import { Trans, useTranslation } from "react-i18next";
import withAppeal, { WithAppealProps } from "src/hoc/withAppeal";

import Appeal from "src/models/Appeal";
import ConditionalContent from "src/components/ConditionalContent";
import Dropdown from "src/components/core/Dropdown";
import ExternalLink from "src/components/core/ExternalLink";
import InputChoiceGroup from "src/components/core/InputChoiceGroup";
import InputText from "src/components/core/InputText";
import { Language } from "src/models/Language";
import { Props } from "types/common";
import QuestionPage from "src/components/QuestionPage";
import React from "react";
import routes from "src/routes";
import useFormState from "src/hooks/useFormState";
import useFunctionalInputProps from "src/hooks/useFunctionalInputProps";

export const fields: Array<keyof Appeal> = [
  "needs_interpreter",
  "interpreter_language_requested",
  "other_interpreter_language_requested",
];

export const Interpreter = (props: WithAppealProps) => {
  const { appLogic, appeal } = props;
  const { t } = useTranslation();

  const { formState, updateFields, clearField, getField } =
    useFormState(appeal);

  const getFunctionalInputProps = useFunctionalInputProps({
    errors: appLogic.errors,
    formState,
    updateFields,
  });

  const handleSave = async () =>
    await appLogic.appeals.update(appeal.appeal_id, formState);

  // Construct the list of Dropdown choices for each language
  const languageChoices: Props<typeof Dropdown>["choices"] = Language.map(
    (language) => ({
      label: language,
      value: language,
    })
  );
  languageChoices.push({
    label: String(t("pages.appealsInterpreter.otherLanguage")),
    value: "other",
  });

  return (
    <QuestionPage
      onSave={handleSave}
      title={t("pages.appealsInterpreter.title")}
    >
      <InputChoiceGroup
        choices={[
          {
            checked: formState.needs_interpreter === true,
            label: t("pages.appealsInterpreter.choiceYes"),
            value: "true",
          },
          {
            checked: formState.needs_interpreter === false,
            label: t("pages.appealsInterpreter.choiceNo"),
            value: "false",
          },
        ]}
        label={t("pages.appealsInterpreter.needsLabel")}
        hint={
          <Trans
            i18nKey="pages.appealsInterpreter.interpreterHint"
            components={{
              "multilingual-services-link": (
                <ExternalLink
                  href={routes.external.massgov.multilingualServices}
                />
              ),
            }}
          />
        }
        type="radio"
        {...getFunctionalInputProps("needs_interpreter")}
      />
      <ConditionalContent
        visible={formState.needs_interpreter === true}
        fieldNamesClearedWhenHidden={[
          "interpreter_language_requested",
          "other_interpreter_language_requested",
        ]}
        clearField={clearField}
        getField={getField}
        updateFields={updateFields}
      >
        <Dropdown
          autocomplete
          choices={languageChoices}
          label={t("pages.appealsInterpreter.languageLabel")}
          {...getFunctionalInputProps("interpreter_language_requested")}
        />
        <ConditionalContent
          visible={formState.interpreter_language_requested === "other"}
          fieldNamesClearedWhenHidden={["other_interpreter_language_requested"]}
          clearField={clearField}
          getField={getField}
          updateFields={updateFields}
        >
          <InputText
            label={t("pages.appealsInterpreter.customLanguageLabel")}
            {...getFunctionalInputProps("other_interpreter_language_requested")}
            smallLabel
          />
        </ConditionalContent>
      </ConditionalContent>
    </QuestionPage>
  );
};

export default withAppeal(Interpreter);
