import {
  EmployerExemptionsPurchasedInsuranceDetails,
  fields as purchasedPlanFields,
} from "./purchased-plan";
import {
  EmployerExemptionsSelfInsuredInsuranceDetails,
  fields as selfInsuredPlanFields,
} from "./self-insured-plan";
import React, { useCallback, useEffect } from "react";
import withEmployerExemptionsApplication, {
  WithEmployerExemptionsApplicationProps,
} from "src/hoc/withEmployerExemptionsApplication";

import BackButton from "src/components/BackButton";
import ConditionalContent from "src/components/ConditionalContent";
import ExternalLink from "src/components/core/ExternalLink";
import Fieldset from "src/components/core/Fieldset";
import FormLabel from "src/components/core/FormLabel";
import HeadingPrefix from "src/components/core/HeadingPrefix";
import InputChoiceGroup from "src/components/core/InputChoiceGroup";
import PageNotFound from "src/components/PageNotFound";
import Title from "src/components/core/Title";
import { Trans } from "react-i18next";
import { isFeatureEnabled } from "src/services/featureFlags";
import routes from "src/routes";
import useFormState from "src/hooks/useFormState";
import useFunctionalInputProps from "src/hooks/useFunctionalInputProps";
import { useTranslation } from "src/locales/i18n";

export const fields = [
  "exemptionRequest.is_self_insured_plan",
  "exemptionRequest.exemption_type",
];

export const EmployerExemptionsInsuranceDetails = (
  props: WithEmployerExemptionsApplicationProps
) => {
  const { appLogic, user, exemptionRequest } = props;
  const { t } = useTranslation();
  const { user_leave_administrators: user_exemption_administrator } = user;

  // use a set of strings to identify exemption type
  const ExemptionType = {
    MEDICAL: "medical",
    FAMILY: "family",
    BOTH: "both",
    get: (
      has_family_exemption: boolean | null,
      has_medical_exemption: boolean | null
    ): string | null => {
      let type = "";
      if (has_family_exemption && has_medical_exemption) {
        type = ExemptionType.BOTH;
      } else if (has_family_exemption) {
        type = ExemptionType.FAMILY;
      } else if (has_medical_exemption) {
        type = ExemptionType.MEDICAL;
      }
      return type;
    },
  } as const;

  const { formState, updateFields } = useFormState({
    is_self_insured_plan: exemptionRequest.is_self_insured_plan ?? "",
    exemption_type: ExemptionType.get(
      exemptionRequest.has_family_exemption,
      exemptionRequest.has_medical_exemption
    ),
    has_family_exemption: exemptionRequest.has_family_exemption ?? "",
    has_medical_exemption: exemptionRequest.has_medical_exemption ?? "",
  });
  // TODO (PFMLPB-19806): removal of feature flag
  const isEnableEmployerExemptionsPortal = isFeatureEnabled(
    "enableEmployerExemptionsPortal"
  );

  const getFunctionalInputProps = useFunctionalInputProps({
    errors: appLogic.errors,
    formState,
    updateFields,
  });

  // this useEffect resets the feilds on the exemption request if the user
  // switches from purchased plan to self-insured
  useEffect(() => {
    if (formState.is_self_insured_plan === true) {
      exemptionRequest.purchased_plan.insurance_provider_id = null;
      exemptionRequest.purchased_plan.insurance_plan_id = null;
      exemptionRequest.insurance_plan_effective_at = null;

      // remove purchased plan plan fields from field array
      // failure to remove will result the display of purchased plan field
      // validation errors
      purchasedPlanFields.forEach((v) => {
        if (fields.indexOf(v) !== -1) {
          fields.splice(fields.indexOf(v), 1);
        }
      });
      // add fields from self insured so validation errors are correct
      fields.push(...selfInsuredPlanFields);
    } else if (formState.is_self_insured_plan === false) {
      exemptionRequest.purchased_plan.insurance_provider_id = null;
      exemptionRequest.self_insured.has_obtained_surety_bond = null;
      exemptionRequest.self_insured.surety_bond_amount = null;
      exemptionRequest.self_insured.surety_company = null;
      exemptionRequest.insurance_plan_effective_at = null;

      // remove purchased plan plan fields from field array
      // failure to remove will result the display of purchased plan field
      // validation errors
      selfInsuredPlanFields.forEach((v) => {
        if (fields.indexOf(v) !== -1) {
          fields.splice(fields.indexOf(v), 1);
        }
      });
      // add fields from puchased plan so validation errors are correct
      fields.push(...purchasedPlanFields);
    }
  }, [exemptionRequest, formState.is_self_insured_plan]);

  // This useCallback is called from a useEffect to update the exemption request
  // with the values set in the formState so they can be used down stream
  const updateRequest = useCallback(() => {
    exemptionRequest.has_family_exemption = formState.has_family_exemption;
    exemptionRequest.has_medical_exemption = formState.has_medical_exemption;
    exemptionRequest.is_self_insured_plan = formState.is_self_insured_plan;
  }, [
    exemptionRequest,
    formState.has_family_exemption,
    formState.has_medical_exemption,
    formState.is_self_insured_plan,
  ]);

  // This use effect allows for 2 bools to be correctly set based off the one
  // string selected for exemption type
  useEffect(() => {
    if (formState.exemption_type === ExemptionType.BOTH) {
      updateFields({ has_family_exemption: true, has_medical_exemption: true });
    } else if (formState.exemption_type === ExemptionType.MEDICAL) {
      updateFields({
        has_family_exemption: false,
        has_medical_exemption: true,
      });
    } else if (formState.exemption_type === ExemptionType.FAMILY) {
      updateFields({
        has_family_exemption: true,
        has_medical_exemption: false,
      });
    } else {
      updateFields({
        has_family_exemption: "",
        has_medical_exemption: "",
      });
    }
    updateRequest();
  }, [
    formState.exemption_type,
    ExemptionType.BOTH,
    ExemptionType.MEDICAL,
    ExemptionType.FAMILY,
    updateFields,
    updateRequest,
  ]);

  // this keeps the exemption type question hidden until
  // the type of insurance is selected
  const getTypeOfExemptionPlan = () => {
    const getTypeOfExemptionPlanQuestion = () => {
      return (
        <Fieldset className="margin-top-0">
          <InputChoiceGroup
            {...getFunctionalInputProps("exemption_type")}
            type="radio"
            label={t(
              "pages.employersExemptionsInsuranceDetails.questionTypeOfLeave"
            )}
            smallLabel
            choices={[
              {
                value: ExemptionType.MEDICAL,
                label: t(
                  "pages.employersExemptionsInsuranceDetails.questionTypeOfLeaveOption_Medical"
                ),
                checked:
                  !formState.has_family_exemption &&
                  formState.has_medical_exemption,
              },
              {
                value: ExemptionType.FAMILY,
                label: t(
                  "pages.employersExemptionsInsuranceDetails.questionTypeOfLeaveOption_Family"
                ),
                checked:
                  formState.has_family_exemption &&
                  !formState.has_medical_exemption,
              },
              {
                value: ExemptionType.BOTH,
                label: t(
                  "pages.employersExemptionsInsuranceDetails.questionTypeOfLeaveOption_Both"
                ),
                checked:
                  formState.has_family_exemption &&
                  formState.has_medical_exemption,
              },
            ]}
          />
        </Fieldset>
      );
    };

    return (
      <ConditionalContent
        visible={typeof formState.is_self_insured_plan === "boolean"}
      >
        {getTypeOfExemptionPlanQuestion()}
      </ConditionalContent>
    );
  };

  if (!isEnableEmployerExemptionsPortal) {
    return <PageNotFound />;
  }

  return (
    <React.Fragment>
      <BackButton />
      <form className="usa-form" method="post">
        <HeadingPrefix>
          <Trans
            i18nKey="shared.employerExemptions.employerEIN"
            values={{
              employer_fein: user_exemption_administrator[0].employer_fein,
            }}
          />
        </HeadingPrefix>

        <Title>{t("pages.employersExemptionsInsuranceDetails.title")}</Title>

        <Fieldset>
          <FormLabel
            component="legend"
            hint={
              <Trans
                i18nKey="pages.employersExemptionsInsuranceDetails.sectionLabelHint"
                components={{
                  "benefit-plan-requirements-link": (
                    <ExternalLink
                      href={
                        routes.external.massgov
                          .benefitsGuide_privatePaidLeavePlanExemptions
                      }
                    />
                  ),
                }}
              />
            }
          >
            {t("pages.employersExemptionsInsuranceDetails.sectionLabel")}
          </FormLabel>
          <InputChoiceGroup
            {...getFunctionalInputProps("is_self_insured_plan")}
            type="radio"
            smallLabel
            label={t(
              "pages.employersExemptionsInsuranceDetails.radioSelectHeaderPlanType"
            )}
            choices={[
              {
                value: "false",
                label: t(
                  "pages.employersExemptionsInsuranceDetails.radioSelectOptionPurchased"
                ),
                hint: t(
                  "pages.employersExemptionsInsuranceDetails.radioSelectOptionPurchasedHint"
                ),
                // cannot use !formState.is_self_insured_plan here. a null
                // formState.is_self_insured_plan will return true if ! is used
                // using expclicit === false
                checked: formState.is_self_insured_plan === false,
              },
              {
                value: "true",
                label: t(
                  "pages.employersExemptionsInsuranceDetails.radioSelectOptionSelfInsured"
                ),
                hint: t(
                  "pages.employersExemptionsInsuranceDetails.radioSelectOptionSelfInsuredHint"
                ),
                checked: formState.is_self_insured_plan === true,
              },
            ]}
          />
        </Fieldset>
        {getTypeOfExemptionPlan()}
      </form>
      <ConditionalContent visible={formState.is_self_insured_plan === false}>
        <EmployerExemptionsPurchasedInsuranceDetails {...props} />
      </ConditionalContent>
      <ConditionalContent visible={formState.is_self_insured_plan}>
        <EmployerExemptionsSelfInsuredInsuranceDetails {...props} />
      </ConditionalContent>
    </React.Fragment>
  );
};

export default withEmployerExemptionsApplication(
  EmployerExemptionsInsuranceDetails
);
