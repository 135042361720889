import React from "react";
import { StartEndDates } from "types/time";
import { Trans } from "react-i18next";
import dayjs from "dayjs";
import formatDate from "./formatDate";
import isBlank from "./isBlank";

/**
 * Format the given dates and display them as a range. Dates are
 * formatted using the user's default locale, which produces
 * internationalized, human-readable strings.
 * @example formatDateRange("2020-09-01", "2020-10-31") // output: "9/1/2020 to 10/31/2020"
 * @example formatDateRange("2020-09-01", "2020-10-31", true) // output: "September 1, 2020 to October 31, 2020"
 * @example formatDateRange("2020-09-01") // output: "9/1/2020"
 */
export function formatDateRange(
  startIsoDate: string | dayjs.Dayjs | null,
  endIsoDate: string | dayjs.Dayjs | null,
  useFullDates = false,
  locale?: string
): React.ReactElement {
  if (isBlank(startIsoDate) || isBlank(endIsoDate)) {
    return <React.Fragment />;
  }

  let endDate, startDate;
  if (useFullDates) {
    startDate = formatDate(startIsoDate, locale).full();
    endDate = formatDate(endIsoDate, locale).full();
  } else {
    // default to short
    startDate = formatDate(startIsoDate, locale).short();
    endDate = formatDate(endIsoDate, locale).short();
  }

  return <Trans i18nKey="shared.dateRange" values={{ startDate, endDate }} />;
}

export function formatDatePeriod(
  period: StartEndDates,
  useFullDates?: boolean,
  locale?: string
): React.ReactElement {
  return formatDateRange(
    period.startDate,
    period.endDate,
    useFullDates,
    locale
  );
}
