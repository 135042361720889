import React, { useEffect, useMemo } from "react";
import withEmployerExemptionsApplication, {
  WithEmployerExemptionsApplicationProps,
} from "src/hoc/withEmployerExemptionsApplication";

import Dropdown from "src/components/core/Dropdown";
import ExternalLink from "src/components/core/ExternalLink";
import Fieldset from "src/components/core/Fieldset";
import FormLabel from "src/components/core/FormLabel";
import Heading from "src/components/core/Heading";
import InsurancePlanInputDate from "./InsurancePlanInputDate";
import QuestionPage from "src/components/QuestionPage";
import { Trans } from "react-i18next";
import routes from "src/routes";
import useFormState from "src/hooks/useFormState";
import useFunctionalInputProps from "src/hooks/useFunctionalInputProps";
import { useTranslation } from "src/locales/i18n";

export const fields = [
  "exemptionRequest.purchased_plan.insurance_provider_id",
  "exemptionRequest.purchased_plan.insurance_plan_id",
  "exemptionRequest.insurance_plan_effective_at",
];

export const EmployerExemptionsPurchasedInsuranceDetails = (
  props: WithEmployerExemptionsApplicationProps
) => {
  const { appLogic, exemptionRequest } = props;
  const { t } = useTranslation();
  const { formState, updateFields } = useFormState({
    purchased_plan: {
      insurance_provider_id:
        exemptionRequest.purchased_plan?.insurance_provider_id ?? -1,
      insurance_plan_id:
        exemptionRequest.purchased_plan?.insurance_plan_id ?? -1,
    },
    insurance_plan_effective_at:
      exemptionRequest.insurance_plan_effective_at ?? "",
  });

  const {
    insuranceProviders,
    insuranceProviderPlans,
    loadInsuranceProviders,
    loadInsuranceProviderPlans,
  } = appLogic.insuranceProviders;

  // keep insurance provider choices form constantly rerendering
  const insuranceProviderChoices = useMemo(
    () =>
      insuranceProviders.map((provider) => {
        return {
          label: provider.insurance_provider_name,
          value: provider.insurance_provider_id,
        };
      }),
    [insuranceProviders]
  );

  // keep insurance plans from rerendering constantly
  const insuranceProviderPlanChoices = useMemo(
    () =>
      insuranceProviderPlans
        .filter((plan) => {
          return (
            JSON.stringify([
              exemptionRequest.has_family_exemption,
              exemptionRequest.has_medical_exemption,
            ]) ===
            JSON.stringify([
              plan.has_family_exemption,
              plan.has_medical_exemption,
            ])
          );
        })
        .map((plan) => ({
          label: plan.form_name,
          value: plan.insurance_plan_id,
        })),
    [
      insuranceProviderPlans,
      exemptionRequest.has_family_exemption,
      exemptionRequest.has_medical_exemption,
    ]
  );

  // load insurance providers on load of the page
  useEffect(() => {
    loadInsuranceProviders();
  }, [loadInsuranceProviders]);

  // load plans for a provider given specific changes occur
  useEffect(() => {
    loadInsuranceProviderPlans(formState.purchased_plan.insurance_provider_id);
  }, [
    exemptionRequest.has_family_exemption,
    exemptionRequest.has_medical_exemption,
    formState.purchased_plan.insurance_provider_id,
    loadInsuranceProviderPlans,
  ]);

  // reset the form if the insurance type is no longer a purchased plan
  useEffect(() => {
    if (formState.is_self_insured_plan === true) {
      updateFields({
        purchased_plan: {
          insurance_provider_id: -1,
          insurance_plan_id: -1,
        },
        insurance_plan_effective_at: "",
      });
    }
  }, [formState.is_self_insured_plan, updateFields]);

  const getFunctionalInputProps = useFunctionalInputProps({
    errors: appLogic.errors,
    formState,
    updateFields,
  });

  // save changes to the DB and include the values set on the parent page
  const handleSave = async () => {
    await appLogic.employerExemptionsApplication.update(
      exemptionRequest.employer_exemption_application_id,
      {
        ...formState,
        is_self_insured_plan: false,
        // has_family_exemption, has_medical_exemption may have been set to
        // empty string in .employer-exemptions/insurance-details.tsx
        // if yes, send null rather than empty string
        has_family_exemption:
          exemptionRequest.has_family_exemption?.toString().length === 0
            ? null
            : exemptionRequest.has_family_exemption,
        has_medical_exemption:
          exemptionRequest.has_medical_exemption?.toString().length === 0
            ? null
            : exemptionRequest.has_medical_exemption,

        insurance_plan_effective_at:
          formState.insurance_plan_effective_at === ""
            ? null
            : formState.insurance_plan_effective_at,
      }
    );
  };

  const getPrivatePlanProvider = () => {
    return (
      <Dropdown
        choices={insuranceProviderChoices}
        {...getFunctionalInputProps("purchased_plan.insurance_provider_id")}
        emptyChoiceLabel={t(
          "pages.employersExemptionsInsuranceDetails.dropdownDefault"
        )}
        label={t(
          "pages.employersExemptionsInsuranceDetails.questionPlanProvider"
        )}
        smallLabel
        onChange={(event) =>
          updateFields({
            purchased_plan: {
              ...formState.purchased_plan,
              insurance_provider_id: parseInt(event.target.value, 10) || -1,
            },
          })
        }
      />
    );
  };

  const getPrivatePlanFromProvider = () => {
    return (
      <Dropdown
        {...getFunctionalInputProps("purchased_plan.insurance_plan_id")}
        choices={insuranceProviderPlanChoices}
        emptyChoiceLabel={t(
          "pages.employersExemptionsInsuranceDetails.dropdownDefault"
        )}
        label={t(
          "pages.employersExemptionsInsuranceDetails.questionPlanNumberFromProvider"
        )}
        smallLabel
        onChange={(event) =>
          updateFields({
            purchased_plan: {
              ...formState.purchased_plan,
              insurance_plan_id: parseInt(event.target.value, 10) || -1,
            },
          })
        }
      />
    );
  };

  return (
    <React.Fragment>
      <QuestionPage title={""} onSave={handleSave} displayBackButton={false}>
        <Fieldset>
          <Heading level="3">
            <Trans i18nKey="pages.employersExemptionsInsuranceDetails.purchasedSectionHeading" />
          </Heading>
          {getPrivatePlanProvider()}
          <FormLabel
            small
            hint={
              <Trans
                i18nKey="pages.employersExemptionsInsuranceDetails.purchasedSectionPlanNumber_hint"
                components={{
                  "pfml-confirmation-of-insurance-link": (
                    <ExternalLink
                      href={
                        routes.external.massgov
                          .purchasedPrivatePlanConfirmationForm
                      }
                    />
                  ),
                }}
              />
            }
          >
            {t(
              "pages.employersExemptionsInsuranceDetails.purchasedSectionPlanNumber_label"
            )}
          </FormLabel>
          {getPrivatePlanFromProvider()}
          <FormLabel
            small
            hint={
              <Trans i18nKey="pages.employersExemptionsInsuranceDetails.purchasedSectionEffectiveDates_hint" />
            }
          >
            {t(
              "pages.employersExemptionsInsuranceDetails.purchasedSectionEffectiveDates_label"
            )}
          </FormLabel>
          <InsurancePlanInputDate
            labelTranslationKey={"questionPlanEfectiveDate_label"}
            inputProps={getFunctionalInputProps("insurance_plan_effective_at")}
            value={formState.insurance_plan_effective_at}
          />
        </Fieldset>
      </QuestionPage>
    </React.Fragment>
  );
};

export default withEmployerExemptionsApplication(
  EmployerExemptionsPurchasedInsuranceDetails
);
