import React from "react";
import classnames from "classnames";
import isBlank from "src/utils/isBlank";
import useUniqueId from "src/hooks/useUniqueId";

interface Props {
  /**
   * HTML `aria-controls` attribute. Used to indicate that the input affects
   * another element.
   */
  "aria-controls"?: string;
  /**
   * Sets the input's `checked` state. Use this in combination with `onChange`
   * for a controlled component.
   */
  checked?: boolean;
  /**
   * Additional classes to include on the field
   */
  className?: string;
  /**
   * HTML input `disabled` attribute
   */
  disabled?: boolean;
  /**
   * Localized error message. Setting this enables the error state styling.
   */
  errorMsg?: React.ReactNode;
  /**
   * Unique identifier for input
   */
  id?: string;
  /**
   * Localized hint text
   */
  hint?: React.ReactNode;
  /**
   * Localized field label
   */
  label: React.ReactNode;
  /**
   * HTML input `name` attribute
   */
  name: string;
  /**
   * HTML input `onChange` attribute
   */
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  /**
   * HTML input `type` attribute
   */
  type?: "checkbox" | "radio";
  /**
   * Sets the input's `value`
   */
  value: number | string;
}

/**
 * A checkbox or radio field. In most cases, you shouldn't use this
 * component directly. Instead, use `InputChoiceGroup` which renders
 * markup for the entire fieldset.
 *
 * [USWDS Reference ↗](https://designsystem.digital.gov/components/form-controls)
 */
function InputChoice({ type = "checkbox", ...props }: Props) {
  const hasError = !isBlank(props.errorMsg);
  const inputId = useUniqueId("InputChoice");
  const errorMsgId = inputId + "_error";
  const id = props.id || inputId;

  const fieldClassName = classnames(
    `usa-${type} measure-5 bg-transparent`,
    props.className,
    {
      [`usa-${type} measure-5 bg-transparent--error`]: hasError,
    }
  );

  const labelClasses = classnames(`usa-${type}__label`, props.className);

  return (
    <div className={fieldClassName}>
      <input
        checked={props.checked}
        className={`usa-${type}__input`}
        disabled={props.disabled}
        id={id}
        name={props.name}
        onChange={props.onChange}
        type={type}
        value={props.value}
        aria-controls={props["aria-controls"]}
      />
      <label className={labelClasses} htmlFor={id}>
        {props.label}

        {!isBlank(props.hint) && (
          <span className={`usa-${type}__label-description text-base-dark`}>
            {props.hint}
          </span>
        )}
      </label>
      {hasError && (
        <span
          className="usa-error-message measure-5"
          id={errorMsgId}
          role="alert"
        >
          {props.errorMsg}
        </span>
      )}
    </div>
  );
}

export default InputChoice;
