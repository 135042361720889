import EpisodicLeavePeriodDetail from "./EpisodicLeavePeriodDetail";
import { LeaveReasonType } from "./LeaveReason";
import { ValuesOf } from "types/common";
import { groupBy } from "lodash";

export const AbsencePeriodRequestDecision = {
  approved: "Approved",
  cancelled: "Cancelled",
  denied: "Denied",
  inReview: "In Review",
  pending: "Pending",
  // Projected is (at least) used for Bonding Leave periods created automatically
  // for periods with Birth Disability as the reason qualifier
  projected: "Projected",
  voided: "Voided",
  withdrawn: "Withdrawn",
} as const;

export type AbsencePeriodRequestDecisionEnum = ValuesOf<
  typeof AbsencePeriodRequestDecision
>;

export type AbsencePeriodTypes =
  | "Continuous"
  | "Intermittent"
  | "Reduced Schedule";

export class AbsencePeriod {
  absence_period_end_date: string;
  fineos_absence_period_id: string | null = null;
  absence_period_start_date: string;
  episodic_leave_period_detail: EpisodicLeavePeriodDetail | null = null;
  fineos_leave_request_id: string | null = null;
  modified_end_date: string | null = null;
  modified_start_date: string | null = null;
  period_type: AbsencePeriodTypes | null = null;
  reason: LeaveReasonType | null;
  reason_qualifier_one: string | null;
  reason_qualifier_two: string | null;
  request_decision: AbsencePeriodRequestDecisionEnum | null;

  constructor(attrs: Partial<AbsencePeriod> = {}) {
    Object.assign(this, attrs);
  }

  get hasFinalDecision() {
    // Mimics the API's has_final_decision helper.
    const nonFinalDecisions: Array<AbsencePeriodRequestDecisionEnum | null> = [
      "Pending",
      "In Review",
      "Projected",
      null,
    ];

    return !nonFinalDecisions.includes(this.request_decision);
  }

  get isApproved() {
    return this.request_decision === AbsencePeriodRequestDecision.approved;
  }

  get isPending() {
    return this.request_decision === AbsencePeriodRequestDecision.pending;
  }

  get startDate() {
    if (this.modified_start_date != null) {
      return this.modified_start_date;
    }
    return this.absence_period_start_date;
  }

  get endDate() {
    if (this.modified_end_date != null) {
      return this.modified_end_date;
    }
    return this.absence_period_end_date;
  }

  static groupByReason(absence_periods: AbsencePeriod[]): {
    [reason: string]: AbsencePeriod[];
  } {
    return groupBy(absence_periods, "reason");
  }

  /**
   * @returns periods sorted newest to oldest (by start date)
   */
  static sortNewToOld(absence_periods: AbsencePeriod[]): AbsencePeriod[] {
    const periods = absence_periods.slice(); // avoids mutating the original array
    return periods.sort((a, b) => {
      return a.startDate > b.startDate ? -1 : 1;
    });
  }
}

/**
 * Statuses (request_decision) values that represent a terminal status for an absence period (aka it is done!)
 */
export const finalDecisions: AbsencePeriodRequestDecisionEnum[] = [
  AbsencePeriodRequestDecision.withdrawn,
  AbsencePeriodRequestDecision.approved,
  AbsencePeriodRequestDecision.denied,
  AbsencePeriodRequestDecision.cancelled,
  AbsencePeriodRequestDecision.voided,
];
