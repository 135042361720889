import { FormStateBody } from "src/hooks/useFormState";

export default function getNestedFormStateValue(
  formState: FormStateBody,
  field: string
) {
  const arr = field.split(".");
  let nestedValue = formState;

  for (const str of arr) {
    if (typeof nestedValue === "object" && nestedValue.hasOwnProperty(str)) {
      nestedValue = nestedValue[str];
    } else {
      return undefined;
    }
  }

  return nestedValue;
}
