import tracker from "src/services/tracker";
import { useEffect } from "react";

/**
 * Tracks tab or window close
 */
const useTrackerTabClose = () => {
  useEffect(() => {
    const logTabClose = () => {
      const actionName = "Tab Close";
      const url = document.location.pathname + document.location.search;
      const friendlyDescription = `User closed the tab on ${url}`;

      tracker.trackEvent(actionName, { friendlyDescription });
    };

    window.addEventListener("beforeunload", logTabClose);

    return () => window.removeEventListener("beforeunload", logTabClose);
  }, []);
};

export default useTrackerTabClose;
