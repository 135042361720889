import React, { useEffect } from "react";
import withUser, { WithUserProps } from "./withUser";

import Appeal from "src/models/Appeal";
import PageNotFound from "src/components/PageNotFound";
import { Spinner } from "src/components/core/Spinner";
import { useTranslation } from "react-i18next";

interface QueryForWithAppeal {
  appeal_id?: string;
}

export interface WithAppealProps extends WithUserProps {
  appeal: Appeal;
}

function withAppeal<T extends WithAppealProps>(
  Component: React.ComponentType<T>
) {
  const ComponentWithAppeal = (
    props: WithUserProps & { query: QueryForWithAppeal }
  ) => {
    const { appLogic, query } = props;
    const appeal_id = query.appeal_id;
    const appeal = appeal_id
      ? appLogic.appeals.appeals.getItem(appeal_id)
      : null;
    const { t } = useTranslation();

    useEffect(() => {
      if (!appeal && appeal_id) {
        appLogic.appeals.load(appeal_id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appeal, appeal_id]);

    if (!appeal_id) {
      return <PageNotFound />;
    }

    if (!appeal) {
      return (
        <div className="margin-top-8 text-center">
          <Spinner aria-label={t("components.withAppeal.loadingLabel")} />
        </div>
      );
    }

    return (
      <Component
        {...(props as T & { query: QueryForWithAppeal })}
        appeal={appeal}
      />
    );
  };

  return withUser(ComponentWithAppeal);
}

export default withAppeal;
