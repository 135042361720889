import {
  InsuranceProvider,
  InsuranceProviderPlans,
} from "src/models/InsuranceProvider";

import BaseApi from "./BaseApi";
import routes from "src/routes";

export default class InsuranceProvidersApi extends BaseApi {
  get basePath() {
    return routes.api.insuranceProviders;
  }

  get namespace(): string {
    return "insuranceProviders";
  }

  get headers() {
    return [
      { featureFlag: "enableEmployerExemptionsPortal" },
      {
        name: "X-FF-Enable-Employer-Exemptions-Portal",
        value: true,
      },
    ];
  }

  getInsuranceProviders = async () => {
    const { data } = await this.request<InsuranceProvider[]>("GET");

    return data;
  };

  getInsuranceProviderPlans = async (insuranceProviderId: number) => {
    const { data } = await this.request<InsuranceProviderPlans>(
      "GET",
      `${insuranceProviderId}`
    );

    return data.insurance_plans;
  };
}
