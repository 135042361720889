import React, { useEffect } from "react";

import { AbsencePeriod } from "src/models/AbsencePeriod";
import { AppLogic } from "src/hooks/useAppLogic";
import InputChoiceGroup from "src/components/core/InputChoiceGroup";
import LeaveReason from "src/models/LeaveReason";
import { NullableQueryParams } from "src/utils/routeWithParams";
import QuestionPage from "src/components/QuestionPage";
import Spinner from "src/components/core/Spinner";
import { TFunction } from "i18next";
import { ValidationError } from "src/errors";
import tracker from "src/services/tracker";
import useFormState from "src/hooks/useFormState";
import useFunctionalInputProps from "src/hooks/useFunctionalInputProps";
import { useTranslation } from "react-i18next";

export const UploadType = {
  mass_id: "UPLOAD_MASS_ID",
  non_mass_id: "UPLOAD_ID",
  medical_certification: "UPLOAD_MEDICAL_CERTIFICATION",
  proof_of_birth: "UPLOAD_PROOF_OF_BIRTH",
  proof_of_placement: "UPLOAD_PROOF_OF_PLACEMENT",
  pregnancy_medical_certification: "UPLOAD_PREGNANCY_MEDICAL_CERTIFICATION",
  caring_leave_certification: "UPLOAD_CARING_LEAVE_CERTIFICATION",
  military_caregiver_form: "UPLOAD_MILITARY_CAREGIVER_FORM",
  military_caregiver_proof: "UPLOAD_MILITARY_CAREGIVER_PROOF",
  military_exigency_form: "UPLOAD_MILITARY_EXIGENCY_FORM",
  military_exigency_proof: "UPLOAD_MILITARY_EXIGENCY_PROOF",
};

interface Props {
  appLogic: AppLogic;
  query: {
    absence_id: string;
  };
}

export const UploadDocsOptions = (props: Props) => {
  const {
    appLogic,
    query: { absence_id },
  } = props;
  const {
    claims: { claimDetail, isLoadingClaimDetail, loadClaimDetail },
    portalFlow,
  } = appLogic;
  const { t } = useTranslation();

  const { formState, updateFields } = useFormState();
  const upload_docs_options = formState.upload_docs_options;

  useEffect(() => {
    loadClaimDetail(absence_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [absence_id]);

  const getFunctionalInputProps = useFunctionalInputProps({
    errors: appLogic.errors,
    formState,
    updateFields,
  });

  const choices = claimDetail
    ? getInputChoices(claimDetail.absence_periods, upload_docs_options, t)
    : [];

  if (isLoadingClaimDetail || !claimDetail)
    return (
      <div className="margin-top-8 text-center">
        <Spinner aria-label={t("pages.claimsStatus.loadingClaimDetailLabel")} />
      </div>
    );

  const handleSave = async () => {
    if (upload_docs_options == null) {
      const validation_error = new ValidationError([
        {
          field: "upload_docs_options",
          type: "required",
          namespace: "applications",
        },
      ]);
      appLogic.catchError(validation_error);

      tracker.trackEvent("ValidationError", {
        issueField: validation_error.issues[0].field ?? "",
        issueType: validation_error.issues[0].type ?? "",
      });

      return;
    }

    const params: NullableQueryParams = {
      claim_id: claimDetail.application_id,
      absence_id: claimDetail.fineos_absence_id,
    };

    if (isCertification(upload_docs_options)) {
      params.document_type = upload_docs_options;
    }

    return await portalFlow.goToNextPage({}, params, upload_docs_options);
  };

  return (
    <QuestionPage
      title={t("pages.claimsUploadDocsOptions.title")}
      onSave={handleSave}
    >
      <InputChoiceGroup
        {...getFunctionalInputProps("upload_docs_options")}
        choices={choices}
        label={t("pages.claimsUploadDocsOptions.sectionLabel")}
        hint={t("pages.claimsUploadDocsOptions.sectionHint")}
        type="radio"
      />
    </QuestionPage>
  );
};

export default UploadDocsOptions;

/**
 * Determine what choices to show based on the absence period's leave reason
 */
const getInputChoices = (
  absencePeriods: AbsencePeriod[],
  currentValue: string,
  t: TFunction
) => {
  const choices = [
    {
      checked: currentValue === UploadType.mass_id,
      label: t("pages.claimsUploadDocsOptions.stateIdLabel"),
      value: UploadType.mass_id,
    },
    {
      checked: currentValue === UploadType.non_mass_id,
      label: t("pages.claimsUploadDocsOptions.nonStateIdLabel"),
      value: UploadType.non_mass_id,
    },
  ];

  if (
    absencePeriods.some((absencePeriod) => {
      return (
        absencePeriod.reason === LeaveReason.bonding &&
        absencePeriod.reason_qualifier_one === "Newborn"
      );
    })
  ) {
    choices.push({
      checked: currentValue === UploadType.proof_of_birth,
      label: t("pages.claimsUploadDocsOptions.certLabel_bonding_newborn", {
        context: "bonding_newborn",
      }),
      value: UploadType.proof_of_birth,
    });
  }

  if (
    absencePeriods.some((absencePeriod) => {
      return (
        absencePeriod.reason === LeaveReason.bonding &&
        (absencePeriod.reason_qualifier_one === "Adoption" ||
          absencePeriod.reason_qualifier_one === "Foster Care")
      );
    })
  ) {
    choices.push({
      checked: currentValue === UploadType.proof_of_placement,
      label: t("pages.claimsUploadDocsOptions.certLabel_bonding_adopt_foster", {
        context: "bonding_adopt_foster",
      }),
      value: UploadType.proof_of_placement,
    });
  }

  if (
    absencePeriods.some((absencePeriod) => {
      return absencePeriod.reason === LeaveReason.pregnancy;
    })
  ) {
    choices.push({
      checked: currentValue === UploadType.pregnancy_medical_certification,
      label: t("pages.claimsUploadDocsOptions.certLabel_medical", {
        // TODO (CP-2660): change context for pregnancy
        context: "medical",
      }),
      value: UploadType.pregnancy_medical_certification,
    });
  }

  if (
    absencePeriods.some((absencePeriod) => {
      return absencePeriod.reason === LeaveReason.medical;
    })
  ) {
    choices.push({
      checked: currentValue === UploadType.medical_certification,
      label: t("pages.claimsUploadDocsOptions.certLabel_medical", {
        context: "medical",
      }),
      value: UploadType.medical_certification,
    });
  }

  if (
    absencePeriods.some((absencePeriod) => {
      return absencePeriod.reason === LeaveReason.care;
    })
  ) {
    choices.push({
      checked: currentValue === UploadType.caring_leave_certification,
      label: t("pages.claimsUploadDocsOptions.certLabel_care", {
        context: "care",
      }),
      value: UploadType.caring_leave_certification,
    });
  }

  if (
    absencePeriods.some((absencePeriod) => {
      return absencePeriod.reason === LeaveReason.serviceMemberFamily;
    })
  ) {
    choices.push({
      checked: currentValue === UploadType.military_caregiver_proof,
      label: t(
        "pages.claimsUploadDocsOptions.certLabel_military_caregiver_proof",
        {
          context: "military_caregiver_proof",
        }
      ),
      value: UploadType.military_caregiver_proof,
    });
    choices.push({
      checked: currentValue === UploadType.military_caregiver_form,
      label: t(
        "pages.claimsUploadDocsOptions.certLabel_military_caregiver_form",
        {
          context: "military_caregiver_form",
        }
      ),
      value: UploadType.military_caregiver_form,
    });
  }

  if (
    absencePeriods.some((absencePeriod) => {
      return absencePeriod.reason === LeaveReason.activeDutyFamily;
    })
  ) {
    choices.push({
      checked: currentValue === UploadType.military_exigency_proof,
      label: t(
        "pages.claimsUploadDocsOptions.certLabel_military_exigency_proof",
        {
          context: "military_exigency_proof",
        }
      ),
      value: UploadType.military_exigency_proof,
    });
    choices.push({
      checked: currentValue === UploadType.military_exigency_form,
      label: t(
        "pages.claimsUploadDocsOptions.certLabel_military_exigency_form",
        {
          context: "military_exigency_form",
        }
      ),
      value: UploadType.military_exigency_form,
    });
  }

  return choices;
};

const certificationTypes = [
  UploadType.caring_leave_certification,
  UploadType.medical_certification,
  UploadType.pregnancy_medical_certification,
];

function isCertification(documentType: string) {
  return certificationTypes.includes(documentType);
}
