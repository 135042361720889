import React, { useEffect } from "react";

import Button from "./core/Button";
import ButtonLink from "./ButtonLink";
import tracker from "src/services/tracker";
import { useTranslation } from "src/locales/i18n";

interface BackButtonProps {
  label?: string | React.ReactElement;
  href?: string;
  /* Pass in the History API instance to be used for
   * navigating backwards and determining what's in
   * history. Mostly here to support unit testing.
   */
  history?: History;
}

/**
 * Help users go back to the previous page in a multi-page transaction. The
 * intention is to alleviate concerns that going back will result in lost data.
 */
function BackButton(props: BackButtonProps) {
  const [show, setShow] = React.useState(true);

  const { t } = useTranslation();
  let label: string | React.ReactElement = t("components.backButton.label");
  if (props.label != null) {
    label = props.label;
  }
  const behaveLikeBrowserBackButton = !props.href;
  const historyApi = props.history || globalThis.history;

  /**
   * Don't render a Back button if there's no page in the history to go back to
   */
  useEffect(() => {
    if (
      behaveLikeBrowserBackButton &&
      (typeof historyApi === "undefined" || historyApi.length <= 1)
    ) {
      setShow(false);
    }
  }, [behaveLikeBrowserBackButton, historyApi]);

  const handleClick = () => {
    tracker.trackEvent("BackButton clicked", {
      behaveLikeBrowserBackButton: behaveLikeBrowserBackButton.toString(),
    });
    if (behaveLikeBrowserBackButton) {
      historyApi.back();
    }
  };

  let Component;
  let linkProps = { href: "" };
  if (props.href) {
    Component = ButtonLink;
    linkProps = {
      href: props.href,
    };
  } else {
    Component = Button;
  }

  let result = <React.Fragment></React.Fragment>;

  if (show) {
    result = (
      <Component
        {...linkProps}
        onClick={handleClick}
        className="margin-bottom-5"
        variation="unstyled"
      >
        <svg
          className="margin-right-05"
          height="12"
          width="12"
          viewBox="0 0 448 512"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="currentColor"
            d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"
          />
        </svg>
        {label}
      </Component>
    );
  }

  return result;
}

export default BackButton;
