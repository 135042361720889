import {
  DocumentTypeEnum,
  MilitaryDocumentTypeEnum,
} from "src/models/Document";

import TempFile from "src/models/TempFile";

const getDocumentFormData = (
  tempFile: TempFile,
  document_type: DocumentTypeEnum | MilitaryDocumentTypeEnum
) => {
  const formData = new FormData();
  formData.append("document_type", document_type);

  // we use Blob to support IE 11, formData is using "blob" as the default file name,
  // so we pass the actual file name here
  // https://developer.mozilla.org/en-US/docs/Web/API/FormData/append#append_parameters
  formData.append("file", tempFile.file, tempFile.file.name);
  formData.append("name", tempFile.file.name);

  if (tempFile.description) {
    formData.append("description", tempFile.description);
  }

  return formData;
};

export default getDocumentFormData;
