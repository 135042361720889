export class WaitingPeriod {
  waiting_period_end_date: string;
  waiting_period_start_date: string;
  is_benefit_year_waiting_period: boolean;

  constructor(attrs: Partial<WaitingPeriod>) {
    Object.assign(this, attrs);
  }
}

export const WaitingPeriodType = {
  default: "Default",
  intermittentLeave: "IntermittentLeave",
  benefitYear: "BenefitYear",
} as const;

export default WaitingPeriod;
