import { ClaimWithdrawnError, ValidationError } from "src/errors";
import ClaimsApi, { GetClaimsParams } from "src/api/ClaimsApi";

import ApiResourceCollection from "src/models/ApiResourceCollection";
import Claim from "src/models/Claim";
import ClaimDetail from "src/models/ClaimDetail";
import { ErrorsLogic } from "./useErrorsLogic";
import PaginationMeta from "src/models/PaginationMeta";
import { PortalFlow } from "./usePortalFlow";
import dayjs from "dayjs";
import { isEqual } from "lodash";
import useCollectionState from "./useCollectionState";
import { useState } from "react";

const useClaimsLogic = ({
  errorsLogic,
}: {
  errorsLogic: ErrorsLogic;
  portalFlow: PortalFlow;
}) => {
  const claimsApi = new ClaimsApi();

  // Collection of claims for the current user.
  // Initialized to empty collection, but will eventually store the claims
  // as API calls are made to fetch the user's claims
  const { collection: claims, setCollection: setClaims } = useCollectionState(
    new ApiResourceCollection<Claim>("fineos_absence_id")
  );

  // Collection of all claims for the current user.
  // The only difference between allClaims and claims is that allClaims
  // does not reset when a new page of claims is loaded.
  const { collection: allClaims, addItems: addClaims } = useCollectionState(
    new ApiResourceCollection<Claim>("fineos_absence_id")
  );
  const [claimDetail, setClaimDetail] = useState<ClaimDetail>();
  const [isLoadingClaims, setIsLoadingClaims] = useState<boolean>();
  const [isLoadingClaimDetail, setIsLoadingClaimDetail] = useState<boolean>();

  // Pagination info associated with the current collection of claims
  const [paginationMeta, setPaginationMeta] = useState<
    PaginationMeta | { [key: string]: never }
  >({});

  // Track params currently applied for the collection of claims
  const [activeParams, setActiveParams] = useState({});

  // Track if the associated ID has been set from an import
  const [activeAssociatedId, setActiveAssociatedId] = useState("");

  /**
   * Empty the claims collection so that it is fetched again from the API
   */
  const clearClaims = () => {
    setClaims(new ApiResourceCollection<Claim>("fineos_absence_id"));
    // Also clear any indication that a page is loaded, so our loadPage method
    // fetches the page from the API
    setPaginationMeta({});
  };

  /**
   * Load a page of claims for the authenticated user
   */
  const loadPage = async (
    queryParams: GetClaimsParams = {},
    // eslint-disable-next-line @typescript-eslint/no-inferrable-types
    associatedId: string = ""
  ) => {
    if (isLoadingClaims) return;
    // Or have we already loaded this page with the same order and filter params?
    if (
      isEqual(activeAssociatedId, associatedId) &&
      isEqual(activeParams, queryParams) &&
      paginationMeta.page_offset === Number(queryParams.page_offset)
    ) {
      return;
    }

    setIsLoadingClaims(true);
    errorsLogic.clearErrors();

    try {
      const { claims, paginationMeta } = await claimsApi.getClaims(queryParams);
      setClaims(claims);
      addClaims(claims.items);
      setPaginationMeta(paginationMeta);
    } catch (error) {
      errorsLogic.catchError(error);
      // to avoid infinite loop when errors are encountered:
      setPaginationMeta({
        page_offset: Number(queryParams.page_offset),
        page_size: 0,
        total_pages: 0,
        total_records: 0,
        order_by: queryParams.order_by || "",
        order_direction: queryParams.order_direction || "descending",
      });
    } finally {
      setActiveAssociatedId(associatedId);
      setActiveParams(queryParams);
      setIsLoadingClaims(false);
    }
  };

  // On the Claim Status page we're looking at ClaimDetail.required_documents
  // to determine the state of some UI. Because that data is on ClaimDetail
  // it doesn't update when a document is uploaded. This makes that update
  // without calling the API.
  const syncDocumentRequirements = (
    documentRequirementTypeToUpdate: string
  ) => {
    if (!claimDetail?.document_requirements) {
      return;
    }

    const newDocumentRequirements = claimDetail.document_requirements.map(
      (requirement) => {
        if (requirement.document_type === documentRequirementTypeToUpdate) {
          return { ...requirement, upload_date: dayjs().format("YYYY-MM-DD") };
        }
        return requirement;
      }
    );
    setClaimDetail(
      new ClaimDetail({
        ...claimDetail,
        document_requirements: newDocumentRequirements,
      })
    );
  };

  /**
   * Load details for a single claim. Note that if there is already a claim detail being loaded then
   * this function will immediately return undefined.
   * @returns claim detail if we were able to load it. Returns undefined if
   * we're already loading a claim detail or if the request to load the claim detail fails.
   */
  const loadClaimDetail = async (absenceId: string) => {
    if (isLoadingClaimDetail) return;

    if (claimDetail?.fineos_absence_id !== absenceId) {
      try {
        setIsLoadingClaimDetail(true);
        errorsLogic.clearErrors();
        const data = await claimsApi.getClaimDetail(absenceId);
        const newClaimDetail = new ClaimDetail(data.claimDetail);

        setClaimDetail(newClaimDetail);
        // Update application cards in case claim is not in state yet
        // after claimant successfully completes their application
        // and attempts to go back to the /applications index page
        const claimToUpdate =
          claims.getItem(absenceId) ??
          new Claim({
            created_at: newClaimDetail.created_at,
            employee: newClaimDetail.employee,
            employer: newClaimDetail.employer ?? undefined,
            fineos_absence_id: newClaimDetail.fineos_absence_id,
            managed_requirements: newClaimDetail.managed_requirements,
          });
        claimToUpdate.absence_periods = newClaimDetail.absence_periods;
        const newClaimCollection = claims.setItem(claimToUpdate);
        setClaims(newClaimCollection);
        addClaims(newClaimCollection.items);
      } catch (error) {
        if (
          error instanceof ValidationError &&
          error.issues[0].type === "fineos_claim_withdrawn"
        ) {
          // The claim was withdrawn -- we'll need to show an error message to the user
          errorsLogic.catchError(
            new ClaimWithdrawnError(absenceId, error.issues[0])
          );
        } else {
          errorsLogic.catchError(error);
        }
      } finally {
        setIsLoadingClaimDetail(false);
      }
    }
  };

  return {
    allClaims,
    claimDetail,
    claims,
    clearClaims,
    isLoadingClaims,
    isLoadingClaimDetail,
    loadClaimDetail,
    loadPage,
    paginationMeta,
    syncDocumentRequirements,
  };
};

export default useClaimsLogic;
export type ClaimsLogic = ReturnType<typeof useClaimsLogic>;
