import Button from "./core/Button";
import ButtonLink from "./ButtonLink";
import Icon from "./core/Icon";
import LanguageSelector from "./LanguageSelector";
import React from "react";
import { Trans } from "react-i18next";
import User from "src/models/User";
import routes from "src/routes";
import { useTranslation } from "src/locales/i18n";

interface AuthNavProps {
  user?: User;
  updateUser?: (
    user_id: User["user_id"],
    patchData: Partial<User>
  ) => Promise<User | undefined>;
  onLogout: React.MouseEventHandler<HTMLButtonElement>;
}

/**
 * Displays auth links and info about the user if they're logged in
 */
const AuthNav = ({ user, onLogout, updateUser }: AuthNavProps) => {
  const { t } = useTranslation();
  const isLoggedIn = !!user?.email_address;
  const showSettings = user?.consented_to_data_sharing;
  const showTaxDocuments = isLoggedIn && !user?.has_multiple_tax_identifiers;

  const BackLink = () => {
    return (
      <div className="grid-col">
        <ButtonLink
          href={routes.external.massgov.paidLeave}
          className="width-auto"
          inversed
          variation="unstyled"
        >
          {t("components.authNav.backButton")}
        </ButtonLink>
      </div>
    );
  };

  const SettingsLink = () => {
    return (
      <ButtonLink
        variation="unstyled"
        inversed
        className="width-auto margin-right-2"
        href={routes.user.settings}
      >
        <Icon
          name="account_circle"
          className="margin-right-05 position-relative text-middle"
          size={3}
        />
        {t("components.header.settingsLinkText")}
      </ButtonLink>
    );
  };

  const TaxDocumentsLink = () => {
    return (
      <ButtonLink
        variation="unstyled"
        inversed
        className="width-auto margin-right-2"
        href={routes.user.taxDocuments}
      >
        <Icon
          name="topic"
          className="margin-right-05 position-relative text-middle"
          size={3}
        />
        {t("components.header.taxDocumentsLinkText")}
      </ButtonLink>
    );
  };

  const LoginLink = () => {
    return (
      <ButtonLink
        href={routes.auth.oAuthStart}
        className="width-auto margin-right-2"
        inversed
        variation="unstyled"
      >
        <Icon
          name="login"
          className="margin-right-05 position-relative text-middle"
          size={3}
        />
        {t("components.authNav.logInLink")}
      </ButtonLink>
    );
  };

  const LogoutLink = () => {
    return (
      <Button
        className="width-auto margin-right-2"
        inversed
        onClick={onLogout}
        variation="unstyled"
      >
        <Icon
          name="logout"
          className="margin-right-05 position-relative text-middle"
          size={3}
        />
        {t("components.authNav.logOutButton")}
      </Button>
    );
  };

  const LanguageSelectorMenuLink = () => (
    <span className="display-inline-block" data-testid="language_selector">
      <LanguageSelector user={user} updateUser={updateUser} />
    </span>
  );

  const LoggedInNavbar = () => {
    return (
      <React.Fragment>
        {showTaxDocuments && <TaxDocumentsLink />}
        {!showSettings && (
          <span className="display-inline-block" data-testid="email_address">
            <Trans
              i18nKey="data.user.any"
              values={{
                value: user?.email_address,
              }}
            />
          </span>
        )}
        {showSettings && <SettingsLink />}
        <LanguageSelectorMenuLink />
        <LogoutLink />
      </React.Fragment>
    );
  };

  const LoggedOutNavbar = () => {
    return (
      <React.Fragment>
        <LanguageSelectorMenuLink />
        <LoginLink />
      </React.Fragment>
    );
  };

  return (
    <div className="flex-fill margin-y-1 margin-left-2">
      <div className="grid-row grid-gap">
        {!isLoggedIn && <BackLink />}
        <div className="grid-col text-right">
          {isLoggedIn ? <LoggedInNavbar /> : <LoggedOutNavbar />}
        </div>
      </div>
    </div>
  );
};

export default AuthNav;
