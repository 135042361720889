import OAuthFlowStart, { OAuthTokenResponse } from "src/models/OAuth";

import BaseApi from "./BaseApi";
import routes from "src/routes";

export default class OAuthTokenApi extends BaseApi {
  get basePath() {
    return routes.api.oAuthToken;
  }

  get namespace() {
    return "auth";
  }

  getOAuthToken = async (
    lmg_auth_uri: OAuthFlowStart,
    auth_code: string,
    state: string
  ) => {
    const auth_code_res = {
      code: auth_code,
      state,
    };
    const requestBody = {
      auth_uri_res: lmg_auth_uri,
      auth_code_res,
    };
    const { data } = await this.request<OAuthTokenResponse>(
      "POST",
      "",
      requestBody,
      {
        excludeAuthHeader: true,
      }
    );

    return Promise.resolve(new OAuthTokenResponse(data));
  };
}
