import withEmployerExemptionsApplication, {
  WithEmployerExemptionsApplicationProps,
} from "src/hoc/withEmployerExemptionsApplication";

import FormLabel from "src/components/core/FormLabel";
import PageNotFound from "src/components/PageNotFound";
import QuestionPage from "src/components/QuestionPage";
import React from "react";
import { Trans } from "react-i18next";
import { isFeatureEnabled } from "src/services/featureFlags";
import useFormState from "src/hooks/useFormState";
import { useTranslation } from "src/locales/i18n";

// TODO (PFMLPB-21646): ENG FE: Has Third-Party Administrator Page Design and Content
// uncomment useFunctionalInputProps as part of PFMLPB-21646
// import useFunctionalInputProps from "src/hooks/useFunctionalInputProps";

/* 
  prefix each field 'exemptionRequest.' this is needed for the employer 
  exemptions progress page to display correctly
  */
export const fields = [
  /* 
  TODO (PFMLPB-21867) Uncomment these once input UI is implemented, as leaving them in without the ability to set values blocks
  the ability for the Save & Continue Button to route to the next page
  */
  //   "exemptionRequest.self_insured.questions.does_plan_pay_enough_benefits",
  //   "exemptionRequest.self_insured.questions.does_employer_withhold_premiums",
  //   "exemptionRequest.self_insured.questions.are_employer_withholdings_within_allowable_amount",
];

export const EmployerExemptionsSelfInsuredBenefitAndContributionDetails = (
  props: WithEmployerExemptionsApplicationProps
) => {
  const { appLogic, exemptionRequest, user } = props;
  const { t } = useTranslation();
  const { user_leave_administrators } = user;

  // TODO (PFMLPB-21867): ENG FE: Exemptions FE: self-insured 3 of 5 design and content
  // uncomment updateFields as part of PFMLPB-21867
  const { formState /* updateFields */ } = useFormState({
    self_insured: {
      questions: {
        does_plan_pay_enough_benefits:
          exemptionRequest.self_insured?.questions
            ?.does_plan_pay_enough_benefits,
        does_employer_withhold_premiums:
          exemptionRequest.self_insured?.questions
            ?.does_employer_withhold_premiums,
        are_employer_withholdings_within_allowable_amount:
          exemptionRequest.self_insured?.questions
            ?.are_employer_withholdings_within_allowable_amount,
      },
    },
  });

  // TODO (PFMLPB-19806): removal of feature flag
  const isEnableEmployerExemptionsPortal = isFeatureEnabled(
    "enableEmployerExemptionsPortal"
  );

  const handleSave = async () => {
    await appLogic.employerExemptionsApplication.update(
      exemptionRequest.employer_exemption_application_id,
      formState
    );
  };

  // TODO (PFMLPB-21867): ENG FE: Exemptions FE: self-insured 3 of 5 design and content
  // uncomment getFunctionalInputProps as part of PFMLPB-21867
  // const getFunctionalInputProps = useFunctionalInputProps({
  //     errors: appLogic.errors,
  //     formState,
  //     updateFields,
  // });

  if (!isEnableEmployerExemptionsPortal) {
    return <PageNotFound />;
  }

  return (
    <React.Fragment>
      <QuestionPage
        title={t(
          "pages.employersExemptionsSelfInsuredBenefitAndContributionDetails.title"
        )}
        headingPrefix={
          <Trans
            i18nKey="shared.employerExemptions.employerEIN"
            values={{
              employer_fein: user_leave_administrators[0].employer_fein,
            }}
          />
        }
        onSave={handleSave}
      >
        <FormLabel component="legend">
          {t(
            "pages.employersExemptionsSelfInsuredBenefitAndContributionDetails.sectionLabel"
          )}
        </FormLabel>
      </QuestionPage>
    </React.Fragment>
  );
};

export default withEmployerExemptionsApplication(
  EmployerExemptionsSelfInsuredBenefitAndContributionDetails
);
