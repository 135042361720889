import withChangeRequest, {
  WithChangeRequestProps,
} from "src/hoc/withChangeRequest";
import withClaimDetail, { WithClaimDetailProps } from "src/hoc/withClaimDetail";

import { AppLogic } from "src/hooks/useAppLogic";
import InputDate from "src/components/core/InputDate";
import QuestionPage from "src/components/QuestionPage";
import React from "react";
import { pick } from "lodash";
import useFormState from "src/hooks/useFormState";
import useFunctionalInputProps from "src/hooks/useFunctionalInputProps";
import { useTranslation } from "src/locales/i18n";

export const fields = ["change_request.date_of_birth"];

type DateOfBirthProps = WithClaimDetailProps &
  WithChangeRequestProps & {
    query: {
      change_request_id: string;
      absence_id: string;
    };
    appLogic: AppLogic;
  };

export const DateOfBirth = (props: DateOfBirthProps) => {
  const { appLogic } = props;
  const { t } = useTranslation();

  const { formState, updateFields } = useFormState(
    pick(props, fields).change_request
  );

  const handleSave = async () => {
    await appLogic.changeRequests.update(
      props.query.change_request_id,
      formState,
      props.claim_detail
    );
  };

  const getFunctionalInputProps = useFunctionalInputProps({
    errors: appLogic.errors,
    formState,
    updateFields,
  });

  return (
    <QuestionPage
      title={t("pages.claimsModifyMedToBondingDateOfBirth.title")}
      onSave={handleSave}
    >
      <InputDate
        {...getFunctionalInputProps("date_of_birth")}
        label={t("pages.claimsModifyMedToBondingDateOfBirth.sectionTitle")}
        hint={t("pages.claimsModifyMedToBondingDateOfBirth.sectionHint")}
        example={t("components.form.dateInputExample")}
        dayLabel={t("components.form.dateInputDayLabel")}
        monthLabel={t("components.form.dateInputMonthLabel")}
        yearLabel={t("components.form.dateInputYearLabel")}
      />
    </QuestionPage>
  );
};

export default withChangeRequest(withClaimDetail(DateOfBirth));
