import BaseApi from "./BaseApi";
import EmployerExemptionsApplication from "src/models/EmployerExemptionsApplication";
import routes from "src/routes";

export default class EmployerExemptionsApi extends BaseApi {
  get basePath() {
    return routes.api.employerExemptionApplications;
  }

  get namespace() {
    return "employer_exemptions";
  }

  get headers() {
    return [
      { featureFlag: "enableEmployerExemptionsPortal" },
      {
        name: "X-FF-Enable-Employer-Exemptions-Portal",
        value: true,
      },
    ];
  }

  getExemptionApplication = async (
    employer_exemption_application_id: string
  ) => {
    const { data, warnings } =
      await this.request<EmployerExemptionsApplication>(
        "GET",
        employer_exemption_application_id
      );
    return {
      exemptionRequest: new EmployerExemptionsApplication(data),
      warnings,
    };
  };

  /**
   * Fetches the list of exemption applications for a user
   */
  getExemptionApplications = async (pageOffset: string | number = 1) => {
    const { data, meta, warnings } = await this.request<
      [
        {
          employer_id: string;
          employer_exemption_applications: EmployerExemptionsApplication[];
        }
      ]
    >("GET", "", {
      order_by: "created_at",
      order_direction: "descending",
      page_offset: pageOffset,
    });

    const exemptionRequests: EmployerExemptionsApplication[] = [];

    data.forEach((employer) => {
      employer.employer_exemption_applications?.forEach((application) =>
        exemptionRequests.push(new EmployerExemptionsApplication(application))
      );
    });

    return {
      exemptionRequests,
      paginationMeta: meta?.paging ?? {},
      warnings,
    };
  };

  createExemptionApplication = async (employer_id: string) => {
    const { data } = await this.request<EmployerExemptionsApplication>(
      "POST",
      "",
      {
        employer_id,
      }
    );

    return {
      exemptionRequest: new EmployerExemptionsApplication(data),
    };
  };

  updateExemptionApplication = async (
    employer_exemption_application_id: string,
    patchData: Partial<EmployerExemptionsApplication>
  ) => {
    const { data, warnings } =
      await this.request<EmployerExemptionsApplication>(
        "PATCH",
        employer_exemption_application_id,
        patchData
      );

    return {
      exemptionRequest: new EmployerExemptionsApplication(data),
      warnings,
    };
  };
}
