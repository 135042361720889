import React, { useEffect } from "react";
import withUser, { WithUserProps } from "./withUser";

import EmployerExemptionsApplication from "src/models/EmployerExemptionsApplication";
import PageNotFound from "src/components/PageNotFound";
import Spinner from "src/components/core/Spinner";
import routes from "src/routes";
import { useTranslation } from "src/locales/i18n";

export interface QueryForWithEmployerExemptionsApplication {
  employer_exemption_application_id: string;
}

export interface WithEmployerExemptionsApplicationProps extends WithUserProps {
  exemptionRequest: EmployerExemptionsApplication;
}

/**
 * Higher order component that loads an exemption application if not yet loaded,
 * then adds a single exemption application to the wrapped component based on query parameters
 */
function withEmployerExemptionsApplication<
  T extends WithEmployerExemptionsApplicationProps
>(Component: React.ComponentType<T>) {
  const ComponentWithExemptionsApplication = (
    props: WithUserProps & { query: QueryForWithEmployerExemptionsApplication }
  ) => {
    const { appLogic, query } = props;
    const { t } = useTranslation();

    const employer_exemption_application_id =
      query.employer_exemption_application_id;
    const exemptionsApplication =
      appLogic.employerExemptionsApplication.employerExemptionsApplication;
    const application = employer_exemption_application_id
      ? exemptionsApplication.getItem(employer_exemption_application_id)
      : undefined;

    const shouldLoad = !!(
      employer_exemption_application_id &&
      !appLogic.employerExemptionsApplication.hasLoadedEmployerExemptionsApplicationAndWarnings(
        employer_exemption_application_id
      )
    );

    useEffect(() => {
      if (shouldLoad) {
        appLogic.employerExemptionsApplication.load(
          employer_exemption_application_id
        );
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldLoad]);

    useEffect(() => {
      const { goTo, pathname } = appLogic.portalFlow;
      if (
        application?.isSubmitted &&
        pathname === routes.employers.employerExemptions.progress // ||
        // TODO (PFMLPB-21078): update route to employer exemptions review page once complete
        //   pathname === routes.employers.employerExemptions.progress)
      ) {
        goTo(routes.employers.welcome);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [application?.employer_exemption_application_id]);

    if (shouldLoad) {
      return (
        <div className="margin-top-8 text-center">
          <Spinner
            aria-label={t(
              "components.withEmployerExemptionsApplications.loadingLabel"
            )}
          />
        </div>
      );
    }

    if (!employer_exemption_application_id || !application) {
      return <PageNotFound />;
    }

    return (
      <Component
        {...(props as T & { query: QueryForWithEmployerExemptionsApplication })}
        exemptionRequest={application}
      />
    );
  };

  return withUser(ComponentWithExemptionsApplication);
}

export default withEmployerExemptionsApplication;
