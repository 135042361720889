/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";

import Icon from "./core/Icon";
import LocalizeLanguage from "src/models/LocalizeLanguageEnum";
import { Trans } from "react-i18next";
import User from "src/models/User";
import transformLocalizeToSystemLanguage from "src/utils/transformLocalizeToSystemLanguage";

interface LanguageSelectorProps {
  user?: User;
  updateUser?: (
    user_id: User["user_id"],
    patchData: Partial<User>
  ) => Promise<User | undefined>;
}

const LanguageSelector = ({ user, updateUser }: LanguageSelectorProps) => {
  const menuItems = [
    {
      label: "English",
      value: LocalizeLanguage.english,
    },
    {
      label: "Español",
      value: LocalizeLanguage.spanish,
    },
    {
      label: "Kreyòl Ayisyen",
      value: LocalizeLanguage.haitian_creole,
    },
    {
      label: "Português",
      value: LocalizeLanguage.portuguese,
    },
    {
      label: "中文",
      value: LocalizeLanguage.chinese_simplified,
    },
    {
      label: "Tiếng Việt",
      value: LocalizeLanguage.vietnamese,
    },
    {
      label: "Français",
      value: LocalizeLanguage.french,
    },
  ];

  const [language, setLanguage] = useState<string>();
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => setIsOpen(!isOpen);

  const handleLanguageChange = async (language: string) => {
    window.Localize?.setLanguage(language);
    setLanguage(language);
    if (user && updateUser) {
      const systemLanguage = transformLocalizeToSystemLanguage(language);
      await updateUser(user.user_id, {
        language_preference: systemLanguage,
      });
    }
    setIsOpen(false);
  };

  return (
    <div className="usa-language-container usa-language--small">
      <ul className="usa-language__primary usa-accordion">
        <li className="usa-language__primary-item">
          <button
            className="usa-button width-auto usa-button--unstyled usa-button--inverse usa-button--outline margin-right-2 usa-language__link"
            type="button"
            aria-expanded={isOpen ? "true" : "false"}
            aria-controls="language-options"
            onClick={toggleIsOpen}
            data-testid="language-selector-toggle"
          >
            <Icon
              className="margin-right-05 position-relative text-middle"
              name="language"
              size={3}
            />
            <Trans i18nKey="components.header.languageSelectionLinkText" />
          </button>
          <ul
            id="language-options"
            className="usa-language__submenu"
            hidden={!isOpen}
            data-testid="language-selector-menu"
          >
            {menuItems.map(({ label, value }) => (
              <li
                className={`usa-language__submenu-item ${
                  language === value ? "selected" : ""
                }`}
                key={value}
              >
                <button
                  className="usa-button width-full text-left"
                  type="button"
                  onClick={() => handleLanguageChange(value)}
                  title={label}
                  data-testid={`language-selector-${value}`}
                  tabIndex={0}
                >
                  <span
                    className="pretranslated-multilanguage-content"
                    lang={value}
                  >
                    {label}
                  </span>
                </button>
              </li>
            ))}
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default LanguageSelector;
