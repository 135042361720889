import LeaveReason, { LeaveReasonType } from "./LeaveReason";
import {
  ManagedRequirement,
  lastReviewedAt,
  wasPreviouslyReviewed,
} from "./ManagedRequirement";

import { AbsencePeriod } from "./AbsencePeriod";
import { AbsencePeriodDates } from "./ClaimDetail";
import ApiResourceCollection from "./ApiResourceCollection";
import { LeaveRequest } from "./LeaveRequest";
import Payment from "./Payment";
import { compareAndLogDifferences } from "src/utils/compareAndLogDifferences";
import dayjs from "dayjs";
import { getAbsencePaidLeaveCaseEndDates } from "src/utils/getAbsencePaidLeaveCaseEndDates";
import isBlank from "src/utils/isBlank";
import { isReviewable } from "src/utils/isReviewable";
import { orderBy } from "lodash";

export abstract class WithClaimProperties {
  managed_requirements: ManagedRequirement[] = [];
  absence_periods: AbsencePeriod[] = [];
  employer_review: EmployerReview | null;
  approval_date: string | null;
  payment_schedule_type: string | null;
  has_extensions: boolean;
  leave_requests: LeaveRequest[] | null;

  /**
   * Return the earliest start date across all absence periods for this claim.
   */
  get startDate() {
    const startDates = this.absence_periods
      .map((period) => period.startDate)
      .sort();

    return startDates[0];
  }

  /**
   * Return the latest end date across all absence periods for this claim.
   */
  get endDate() {
    const endDates = this.absence_periods
      .map((period) => period.endDate)
      .sort()
      .reverse();

    return endDates[0];
  }

  /**
   *  Checks if leave request sync has run for approved claims yet
   *  An approved leave request will always have at least one absence paid leave case,
   *  otherwise the leave request sync has not run yet
   */
  get isLeaveRequestPendingSync() {
    return (
      this.hasOnlyApprovedStatus &&
      this.leave_requests &&
      this.leave_requests.some(
        (leave_request) => leave_request.absence_paid_leave_cases.length === 0
      )
    );
  }

  /**
   *  Returns the latest absence paid leave case in the leave requests for this claim
   */
  get latestAbsencePaidLeaveCaseEndDate() {
    if (!this.leave_requests || this.leave_requests.length < 1) return null;
    const allEndDates = this.leave_requests
      ?.map((leaveReq) => getAbsencePaidLeaveCaseEndDates(leaveReq))
      .flat()
      .sort()
      .reverse();

    return allEndDates[0];
  }

  get leaveDates(): AbsencePeriodDates[] {
    return this.absence_periods.map(({ startDate, endDate }) => ({
      startDate,
      endDate,
    }));
  }

  /**
   * Determine if claim is partially approved - Absence paid leave cases are for approved leave requests. If the claims end date is
   * after the latest absence paid leave case's end date then the claim is partially approved
   */
  get isPartiallyApproved(): boolean {
    if (
      this.hasOnlyApprovedStatus &&
      (!this.latestAbsencePaidLeaveCaseEndDate || !this.endDate)
    )
      return false;
    return (
      this.hasOnlyApprovedStatus &&
      dayjs(this.latestAbsencePaidLeaveCaseEndDate).isBefore(
        dayjs(this.endDate)
      )
    );
  }

  /**
   * Determine if claim is a continuous leave claim
   */
  get isContinuous(): boolean {
    return this.absence_periods.some(
      (absence_period) => absence_period.period_type === "Continuous"
    );
  }

  /**
   * Determine if claim is an intermittent leave claim
   */
  get isIntermittent(): boolean {
    return this.absence_periods.some(
      (absence_period) => absence_period.period_type === "Intermittent"
    );
  }

  /**
   * Determine if claim is a reduced schedule leave claim
   */
  get isReducedSchedule(): boolean {
    return this.absence_periods.some(
      (absence_period) => absence_period.period_type === "Reduced Schedule"
    );
  }

  get hasApprovedStatus() {
    return this.absence_periods.some(
      (absence_period) => absence_period.request_decision === "Approved"
    );
  }

  get hasOnlyApprovedStatus() {
    return this.absence_periods.every(
      (absence_period) => absence_period.request_decision === "Approved"
    );
  }

  get hasOnlyPendingStatus() {
    return this.absence_periods.every(
      (absence_period) => absence_period.request_decision === "Pending"
    );
  }

  get hasTwoAbsencePeriods() {
    return this.absence_periods.length === 2;
  }

  get hasMaxTwoLeaveRequests(): boolean {
    if (!this.leave_requests) {
      return true;
    } else {
      return this.leave_requests.length <= 2;
    }
  }

  get hasMultipleAbsencePeriods() {
    return this.absence_periods.length > 1;
  }

  get hasDeniedWithdrawnOrCancelledStatus() {
    return this.absence_periods.some((absence_period) =>
      ["Withdrawn", "Denied", "Cancelled"].includes(
        absence_period.request_decision ?? ""
      )
    );
  }

  get hasActiveAbsencePeriod() {
    return !this.absence_periods.every((absence_period) =>
      ["Denied", "Withdrawn", "Voided", "Cancelled"].includes(
        absence_period.request_decision ?? ""
      )
    );
  }

  get hasPendingInReviewOrProjectedStatus() {
    return this.absence_periods.some((absence_period) =>
      ["Pending", "In Review", "Projected"].includes(
        absence_period.request_decision ?? ""
      )
    );
  }

  get hasAtLeastOneApprovedAndNonApprovedStatus() {
    return (
      this.hasApprovedStatus &&
      this.absence_periods.some((absence_period) =>
        ["Withdrawn", "Pending", "Denied", "Cancelled"].includes(
          absence_period.request_decision ?? ""
        )
      )
    );
  }

  get latestDeniedWithdrawnOrCancelledStatus() {
    const DeniedWithdrawnOrCancelledStatus = this.absence_periods.filter(
      (period) =>
        period.request_decision === "Denied" ||
        period.request_decision === "Withdrawn" ||
        period.request_decision === "Cancelled"
    );
    if (DeniedWithdrawnOrCancelledStatus.length === 0) return null;
    return DeniedWithdrawnOrCancelledStatus[0].request_decision;
  }

  get lastAbsencePeriod() {
    const sortedAbsencePeriods = this.sortedAbsencePeriodsByDate;
    if (!sortedAbsencePeriods) return null;
    return sortedAbsencePeriods[sortedAbsencePeriods?.length - 1];
  }

  /**
   * Check if any absence period on the case has a non-pending-like status
   */
  get hasFinalDecision() {
    return this.absence_periods.some(
      (absenceItem) => absenceItem.hasFinalDecision
    );
  }

  get hasInReviewStatus() {
    return this.absence_periods.some(
      (absenceItem) => absenceItem.request_decision === "In Review"
    );
  }

  get hasProjectedStatus() {
    return this.absence_periods.some(
      (absenceItem) => absenceItem.request_decision === "Projected"
    );
  }

  get hasPendingStatus() {
    return this.absence_periods.some(
      (absenceItem) => absenceItem.request_decision === "Pending"
    );
  }

  get waitingWeek(): { startDate?: string; endDate?: string } {
    if (this.leaveDates.length) {
      return {
        // API will return absence_periods sorted by start date, waiting week is the first week at the start of the claim
        startDate: this.leaveDates[0].startDate,
        endDate: dayjs(this.leaveDates[0].startDate)
          .add(6, "days")
          .format("YYYY-MM-DD"),
      };
    }
    return {};
  }

  get requestDecisions() {
    return this.absence_periods.map(
      (absence_period) => absence_period.request_decision
    );
  }

  /**
   * Returns earliest start date across all absence periods
   */
  get leaveStartDate() {
    const startDates: string[] = this.absence_periods
      .map((period) => period.startDate)
      .sort();

    if (!startDates.length) return null;

    return startDates[0];
  }

  /**
   * Returns latest end date across all absence periods
   */
  get leaveEndDate() {
    const endDates: string[] = this.absence_periods
      .map((period) => period.endDate)
      .sort();

    if (!endDates.length) return null;

    return endDates[endDates.length - 1];
  }

  /**
   * Determine if claim has an intermittent absence period
   */
  get hasIntermittentPeriod(): boolean {
    return this.absence_periods.some(
      (absence_period) => absence_period.period_type === "Intermittent"
    );
  }

  /**
   * Determine if claim has an intermittent absence period with approved request decision
   */
  get hasApprovedIntermittentAbsencePeriod(): boolean {
    return this.absence_periods.some(
      (absence_period) =>
        absence_period.period_type === "Intermittent" &&
        absence_period.request_decision === "Approved"
    );
  }

  /**
   * Determine if claim has a Caring Leave absence period
   */
  get hasCaringLeavePeriod(): boolean {
    return this.absence_periods.some(
      (absence_period) => absence_period.reason === LeaveReason.care
    );
  }

  pregnancy_reason_qualifier = [
    "Prenatal Disability",
    "Prenatal Care",
    "Postnatal Disability",
    "Birth Disability",
  ];

  leaveReasonIsMedPregnancy(period: AbsencePeriod): boolean {
    return (
      period.reason === LeaveReason.pregnancy ||
      (period.reason === LeaveReason.medical &&
        this.pregnancy_reason_qualifier.includes(
          period.reason_qualifier_one ?? ""
        ))
    );
  }

  /**
   * Determine if claim includes an absence period for medical leave for pregnancy or recovery from childbirth
   */
  get hasPregnancyLeavePeriod(): boolean {
    return this.absence_periods.some((period) => {
      return this.leaveReasonIsMedPregnancy(period);
    });
  }

  /**
   * Determine if claim includes an approved absence period for medical leave for pregnancy or recovery from childbirth
   */
  get hasApprovedPregnancyLeavePeriod(): boolean {
    return this.absence_periods.some((period) => {
      return this.leaveReasonIsMedPregnancy(period) && period.isApproved;
    });
  }

  /**
   * While there's nothing preventing us from instantiating
   * a ClaimDetail with multiple leave reasons,
   * in practice, the business only allows claims to combine
   * bonding reason with a medical pregnancy reason.
   */
  get isNonPregnancyMedicalLeave(): boolean {
    return this.absence_periods.some((period) => {
      return (
        period.reason === LeaveReason.medical &&
        !["Prenatal Disability", "Prenatal Care"].includes(
          period.reason_qualifier_one ?? ""
        )
      );
    });
  }

  /**
   * Determine if claim includes an absence period for bonding
   */
  get hasBondingLeavePeriod(): boolean {
    return this.absence_periods.some((period) => {
      return period.reason === LeaveReason.bonding;
    });
  }

  get bondingAbsencePeriod(): AbsencePeriod | null {
    const bondingAbsencePeriod = this.absence_periods.find(
      (absencePeriod) => absencePeriod.reason === LeaveReason.bonding
    );
    return bondingAbsencePeriod || null;
  }

  /**
   * Determine if claim has combined medical pregnancy and bonding absence periods
   */
  get hasNewbornBondingPeriod(): boolean {
    return this.absence_periods.some((period) => {
      return (
        period.reason === LeaveReason.bonding &&
        period.reason_qualifier_one === "Newborn"
      );
    });
  }

  /**
   * Determine if claim has combined medical pregnancy and bonding absence periods
   */
  get hasMedAndBondingLeavePeriods(): boolean {
    return this.hasPregnancyLeavePeriod && this.hasBondingLeavePeriod;
  }

  /**
   * Whether this claim includes an Approved absence period for bonding
   */
  get hasApprovedBondingLeave(): boolean {
    return this.absence_periods.some((period) => {
      return period.reason === LeaveReason.bonding && period.isApproved;
    });
  }

  /**
   * Whether this claim includes a Pending absence period for bonding
   */
  get hasPendingBondingLeave(): boolean {
    return this.absence_periods.some((period) => {
      return period.reason === LeaveReason.bonding && period.isPending;
    });
  }

  get firstAbsencePeriodLeaveReason(): LeaveReasonType | null {
    if (!this.firstAbsencePeriodByDate) return null;
    return this.firstAbsencePeriodByDate.reason;
  }

  get lastAbsencePeriodLeaveReason(): LeaveReasonType | null {
    if (!this.lastAbsencePeriod) return null;
    return this.lastAbsencePeriod.reason;
  }

  get firstAbsencePeriodByDate(): AbsencePeriod | null {
    const sortedPeriods = this.sortedAbsencePeriodsByDate;
    if (!sortedPeriods) return null;
    return sortedPeriods[0];
  }

  get sortedAbsencePeriodsByDate(): AbsencePeriod[] | null {
    const compareFunction = (a: AbsencePeriod, b: AbsencePeriod) =>
      a.startDate > b.startDate ? 1 : -1;
    const sortedPeriods: AbsencePeriod[] =
      this.absence_periods.sort(compareFunction);
    if (!sortedPeriods.length) return null;
    return sortedPeriods;
  }

  /**
   * Determine if claim endDate is past 30 days
   */
  get is30DaysPastLeaveEnd(): boolean {
    const endDay = dayjs(this.endDate);
    const now = dayjs();

    if (endDay > now) {
      return false;
    }
    const duration = now.diff(endDay, "day");

    return duration > 30;
  }

  /**
   * How many legal notices is this claim expected to have, based on absence period decisions
   */
  get expectedNoticeCount(): number {
    return this.absence_periods.reduce(
      (previousValue, { request_decision }) => {
        const shouldHaveNotice =
          request_decision === "Approved" ||
          request_decision === "Denied" ||
          request_decision === "Withdrawn";

        if (shouldHaveNotice) return previousValue + 1;
        return previousValue;
      },
      0
    );
  }

  /**
   * Get managed requirements for claim by desc date
   */
  get managedRequirementByFollowUpDate(): ManagedRequirement[] {
    return orderBy(
      this.managed_requirements,
      [(managedRequirement) => managedRequirement.follow_up_date],
      ["desc"]
    );
  }

  get is_reviewable() {
    return isReviewable(this.absence_periods, this.managed_requirements);
  }

  get lastReviewedAt() {
    return lastReviewedAt(this.managed_requirements);
  }

  get wasPreviouslyReviewed() {
    return wasPreviouslyReviewed(this.managed_requirements);
  }

  /**
   * Note we use a utility method to share logic across EmployerClaim and Claim
   * until such time where we combine these methods (PORTAL-477 pending)
   */
  get isReviewable() {
    const isReviewableFE = isReviewable(
      this.absence_periods,
      this.managed_requirements
    );

    // Send data to NewRelic
    const isReviewableBE = this.employer_review?.is_reviewable;
    compareAndLogDifferences({
      frontendValue: isReviewableFE.toString(),
      backendValue: isReviewableBE?.toString(),
      relevantTicketID: "PFMLPB-7812",
      functionName: "isReviewable",
      modelName: "Claim",
    });

    return isReviewableFE;
  }

  get hasApprovalNotice() {
    return !!this.approval_date;
  }

  get hasWaitingWeek() {
    return !isBlank(this.waitingWeek.startDate) && !this.isIntermittent;
  }

  get hasWaitingWeekDates() {
    return (
      !isBlank(this.waitingWeek.startDate) && !isBlank(this.waitingWeek.endDate)
    );
  }

  // See services/claims.py:calculate_payment_schedule_type, claimDetail.payment_schedule_type can be:
  // - None/undefined
  // - "Leave Start-Based"
  // - "Sunday-Based"
  get isSundayBasedPaymentSchedule() {
    return this.payment_schedule_type === "Sunday-Based";
  }

  get approvalDate() {
    return this.approval_date ?? "";
  }

  get isApprovedBeforeLeaveStartDate() {
    const leaveStartDate = this.leaveStartDate ?? "";
    return this.approvalDate < leaveStartDate;
  }

  get isRetroactive() {
    const leaveEndDate = this.leaveEndDate ?? "";
    return this.hasApprovalNotice ? leaveEndDate < this.approvalDate : false;
  }

  get isApprovedBeforeFourteenthDayOfClaim() {
    const fourteenthDayOfClaim = dayjs(this.startDate)
      .add(14, "day")
      .format("YYYY-MM-DD");
    return this.approvalDate < fourteenthDayOfClaim;
  }

  validPayments(payments: ApiResourceCollection<Payment>) {
    const startDate = this.waitingWeek.startDate;
    const validPayments =
      this.hasWaitingWeek && startDate
        ? payments.items.filter(
            ({ period_start_date, frontend_status }) =>
              startDate < period_start_date ||
              frontend_status === "Sent to bank"
          )
        : payments.items;
    return validPayments;
  }

  hasPayments(payments: ApiResourceCollection<Payment>) {
    return !!this.validPayments(payments).length;
  }

  /**
   * Determine if claim has approved medical pregnancy and pending bonding absence periods.
   * Meaning this claimant submitted a med to bonding extension to their medical pregnancy leave.
   * This is limited to maximum 2 Leave Requests.
   */
  get isPendingMedToBonding(): boolean {
    return (
      this.has_extensions &&
      this.hasMaxTwoLeaveRequests &&
      this.hasApprovedPregnancyLeavePeriod &&
      this.hasPendingBondingLeave
    );
  }

  /**
   * Determine if claim has pending non med to bonding extension.
   * Meaning this claimant submitted an extension request for their claim.
   * This is limited to maximum 2 Leave Requests.
   * We also filter out those claims that are med to bonding.
   */
  get hasPendingNonMedToBondingExtension(): boolean {
    return (
      !this.isPendingMedToBonding &&
      this.has_extensions &&
      this.hasPendingStatus &&
      this.hasMaxTwoLeaveRequests
    );
  }

  // If this claim has pending extensions: either med to bonding or regular extension
  get hasPendingExtensions(): boolean {
    return (
      this.isPendingMedToBonding || this.hasPendingNonMedToBondingExtension
    );
  }

  /**
   * Determine if claim a pending bonding type extension.
   * Meaning this claimant had a bonding type claim and they extended it.
   */
  get hasPendingExtensionForBondingType(): boolean {
    return (
      this.hasPendingNonMedToBondingExtension && this.hasPendingBondingLeave
    );
  }

  // This isn't used for the med to bonding situation, though it probably could be
  get getLeaveReasonForExtension() {
    if (!this.hasPendingNonMedToBondingExtension) {
      return null;
    }
    const absencePeriods = this.absence_periods;
    const extensionAbsencePeriod =
      AbsencePeriod.sortNewToOld(absencePeriods)[0];
    const leaveReasonForExtensionAbsencePeriod = extensionAbsencePeriod.reason;
    return leaveReasonForExtensionAbsencePeriod;
  }

  /**
   * Determine if claim is an extension is an intermittent leave claim
   */
  get isIntermittentExtension() {
    const latestAbsencePeriod =
      this.absence_periods[this.absence_periods.length - 1];
    return (
      this.has_extensions &&
      latestAbsencePeriod.period_type === "Intermittent" &&
      latestAbsencePeriod.request_decision === "Approved"
    );
  }
}

export interface EmployerReview {
  is_reviewable?: boolean;
  latest_follow_up_date?: string | null;
  earliest_follow_up_date?: string | null;
}
