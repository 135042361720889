import withBenefitsApplication, {
  WithBenefitsApplicationProps,
} from "src/hoc/withBenefitsApplication";

import ApplicationPagePrepopulated from "src/features/universal-profiles/application-page-prepopulated";
import { Ethnicity as EthnicityOptions } from "src/models/BenefitsApplication";
import InputChoiceGroup from "src/components/core/InputChoiceGroup";
import QuestionPage from "src/components/QuestionPage";
import React from "react";
import { get } from "lodash";
import { isFeatureEnabled } from "src/services/featureFlags";
import useFormState from "src/hooks/useFormState";
import useFunctionalInputProps from "src/hooks/useFunctionalInputProps";
import { useTranslation } from "src/locales/i18n";

export const fields = ["claim.ethnicity"];

export const Ethnicity = (props: WithBenefitsApplicationProps) => {
  const { appLogic, claim } = props;
  const { t } = useTranslation();

  const usedProfileData =
    !!claim.fields_to_use_from_user_profile?.includes("raceEthnicity");

  const { formState, updateFields } = useFormState({
    ethnicity: claim.ethnicity || EthnicityOptions.preferNotToAnswer,
  });

  const handleSave = () =>
    appLogic.benefitsApplications.update(claim.application_id, formState);

  const getFunctionalInputProps = useFunctionalInputProps({
    errors: appLogic.errors,
    formState,
    updateFields,
  });

  const ethnicity = get(formState, "ethnicity");

  return (
    <QuestionPage
      title={
        isFeatureEnabled("enableMmgIDV")
          ? t("pages.claimsEthnicity.titleIdv")
          : t("pages.claimsEthnicity.title")
      }
      dataCy="ethnicity-form"
      onSave={handleSave}
      alertBanner={usedProfileData && <ApplicationPagePrepopulated />}
    >
      <InputChoiceGroup
        {...getFunctionalInputProps("ethnicity")}
        choices={[
          {
            checked: ethnicity === EthnicityOptions.preferNotToAnswer,
            label: t("pages.claimsEthnicity.choicePreferNotToAnswer"),
            value: EthnicityOptions.preferNotToAnswer,
          },
          {
            checked: ethnicity === EthnicityOptions.hispanicOrLatino,
            label: t("pages.claimsEthnicity.choiceHispanicOrLatino"),
            value: EthnicityOptions.hispanicOrLatino,
          },
          {
            checked: ethnicity === EthnicityOptions.notHispanicOrLatino,
            label: t("pages.claimsEthnicity.choiceNotHispanicOrLatino"),
            value: EthnicityOptions.notHispanicOrLatino,
          },
        ]}
        type="radio"
        label={t("pages.claimsEthnicity.sectionLabel")}
        hint={t("pages.claimsRace.sectionLabelHint")}
      />
    </QuestionPage>
  );
};

export default withBenefitsApplication(Ethnicity);
