import { InsurancePlan, InsuranceProvider } from "src/models/InsuranceProvider";

import { ErrorsLogic } from "./useErrorsLogic";
import InsuranceProvidersApi from "src/api/InsuranceProvidersApi";
import { useState } from "react";

const useInsuranceProvidersLogic = ({
  errorsLogic,
}: {
  errorsLogic: ErrorsLogic;
}) => {
  const insuranceProvidersApi = new InsuranceProvidersApi();

  const [insuranceProviders, setInsuranceProviders] = useState<
    InsuranceProvider[] | []
  >([]);
  const [insuranceProviderPlans, setinsuranceProviderPlans] = useState<
    InsurancePlan[] | []
  >([]);
  const [isLoadingInsuranceProviders, setIsLoadingInsuranceProviders] =
    useState<boolean>();
  const [hasLoadedInsuranceProviders, setHasLoadedInsuranceProviders] =
    useState<boolean>(false);

  const [isLoadingInsuranceProviderPlans, setIsLoadingInsuranceProviderPlans] =
    useState<boolean>(false);

  const [planInsuranceProviderId, setPlanInsuranceProviderId] =
    useState<number>();

  // Load insurance providers from API
  const loadInsuranceProviders = async () => {
    if (isLoadingInsuranceProviders) return;
    if (hasLoadedInsuranceProviders) return;

    setIsLoadingInsuranceProviders(true);
    errorsLogic.clearErrors();
    try {
      const insuranceProviders =
        await insuranceProvidersApi.getInsuranceProviders();

      setInsuranceProviders(insuranceProviders);
    } catch (error) {
      errorsLogic.catchError(error);
    } finally {
      setIsLoadingInsuranceProviders(false);
      setHasLoadedInsuranceProviders(true);
    }
  };

  const loadInsuranceProviderPlans = async (insuranceProviderId: number) => {
    if (isLoadingInsuranceProviderPlans) return;

    if (
      hasLoadedInsuranceProviders &&
      planInsuranceProviderId === insuranceProviderId
    ) {
      return;
    }

    setIsLoadingInsuranceProviderPlans(true);
    errorsLogic.clearErrors();

    try {
      setPlanInsuranceProviderId(insuranceProviderId);

      if (insuranceProviderId > 0) {
        const insuranceProviderPlans =
          await insuranceProvidersApi.getInsuranceProviderPlans(
            insuranceProviderId
          );

        setinsuranceProviderPlans(insuranceProviderPlans);
      } else {
        setinsuranceProviderPlans([]);
      }
    } catch (error) {
      errorsLogic.catchError(error);
    } finally {
      setIsLoadingInsuranceProviderPlans(false);
      setHasLoadedInsuranceProviders(true);
    }
  };

  return {
    loadInsuranceProviders,
    loadInsuranceProviderPlans,
    insuranceProviders,
    insuranceProviderPlans,
    hasLoadedInsuranceProviders,
    isLoadingInsuranceProviders,
  };
};

export default useInsuranceProvidersLogic;
export type InsuranceProvidersLogic = ReturnType<
  typeof useInsuranceProvidersLogic
>;
