import { AddressFormatResponse, AddressResponse } from "src/models/Experian";

import BaseApi from "./BaseApi";
import routes from "src/routes";

export default class ExperianApi extends BaseApi {
  get basePath() {
    return "";
  }

  get namespace() {
    return "address_search";
  }

  searchAddress = async (address: string) => {
    const encodedAddress = encodeURIComponent(address);
    const { data, warnings } = await this.request<AddressResponse>(
      "GET",
      `${routes.experian.addressSearch}/?address=${encodedAddress}`
    );

    return {
      addresses: new AddressResponse({ ...data }),
      warnings,
    };
  };

  formatAddress = async (addressKey: string) => {
    const { data, warnings } = await this.request<AddressFormatResponse>(
      "GET",
      `${routes.experian.addressFormat}/?global_address_key=${addressKey}`
    );

    return {
      address: new AddressFormatResponse({ ...data }),
      warnings,
    };
  };
}
