import React, { useEffect } from "react";
import withUser, { WithUserProps } from "./withUser";

import ApiResourceCollection from "src/models/ApiResourceCollection";
import ChangeRequest from "src/models/ChangeRequest";
import PageNotFound from "src/components/PageNotFound";
import Spinner from "src/components/core/Spinner";
import filterByClaim from "src/utils/filterByClaim";
import { useTranslation } from "src/locales/i18n";

export interface WithChangeRequestsProps extends WithUserProps {
  change_requests: ApiResourceCollection<ChangeRequest>;
  query: QueryForWithChangeRequests;
}

interface QueryForWithChangeRequests {
  absence_id?: string;
  absence_case_id?: string;
}

function withChangeRequests<T extends WithChangeRequestsProps>(
  Component: React.ComponentType<T>
) {
  const ComponentWithChangeRequests = (props: WithChangeRequestsProps) => {
    const { appLogic, query } = props;
    const { t } = useTranslation();
    const absence_id = (query.absence_id || query.absence_case_id) ?? "";

    const { loadAll, hasLoadedChangeRequests } = appLogic.changeRequests;

    const shouldLoad = !!absence_id && !hasLoadedChangeRequests(absence_id);

    useEffect(() => {
      if (shouldLoad) loadAll(absence_id);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldLoad]);

    if (!absence_id) {
      return <PageNotFound />;
    }

    const changeRequestsForClaim = filterByClaim(
      appLogic.changeRequests.changeRequests.items,
      absence_id
    );

    const changeRequests = new ApiResourceCollection<ChangeRequest>(
      "change_request_id",
      changeRequestsForClaim
    );

    if (shouldLoad) {
      return (
        <div className="margin-top-8 text-center">
          <Spinner
            aria-label={t("components.withChangeRequests.loadingLabel")}
          />
        </div>
      );
    }
    return <Component {...(props as T)} change_requests={changeRequests} />;
  };
  return withUser(ComponentWithChangeRequests);
}

export default withChangeRequests;
