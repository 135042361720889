import withBenefitsApplication, {
  WithBenefitsApplicationProps,
} from "src/hoc/withBenefitsApplication";

import InputText from "src/components/core/InputText";
import QuestionPage from "src/components/QuestionPage";
import React from "react";
import { pick } from "lodash";
import useFormState from "src/hooks/useFormState";
import useFunctionalInputProps from "src/hooks/useFunctionalInputProps";
import { useTranslation } from "src/locales/i18n";

export const fields = ["claim.additional_user_not_found_info.employer_name"];

export const EmployerName = (props: WithBenefitsApplicationProps) => {
  const { appLogic, claim } = props;
  const { t } = useTranslation();

  const { formState, updateFields } = useFormState(
    pick(claim, "additional_user_not_found_info")
  );

  const handleSave = () =>
    appLogic.benefitsApplications.update(claim.application_id, formState);

  const getFunctionalInputProps = useFunctionalInputProps({
    errors: appLogic.errors,
    formState,
    updateFields,
  });

  return (
    <QuestionPage
      title={t("pages.claimsEmploymentStatus.title")}
      onSave={handleSave}
    >
      <InputText
        {...getFunctionalInputProps(
          "additional_user_not_found_info.employer_name"
        )}
        label={t("pages.claimsAdditionalUserNotFoundInfo.employerNameTitle")}
        hint={t("pages.claimsAdditionalUserNotFoundInfo.employerNameHint")}
      />
    </QuestionPage>
  );
};

export default withBenefitsApplication(EmployerName);
