import { ErrorsLogic } from "./useErrorsLogic";
import WaitingPeriod from "src/models/WaitingPeriod";
import WaitingPeriodsApi from "src/api/WaitingPeriodsApi";
import { useState } from "react";

const useWaitingPeriodsLogic = ({
  errorsLogic,
}: {
  errorsLogic: ErrorsLogic;
}) => {
  const waitingPeriodsApi = new WaitingPeriodsApi();

  const [waitingPeriods, setWaitingPeriods] = useState<WaitingPeriod[]>();
  const [isLoadingWaitingPeriods, setIsLoadingWaitingPeriods] =
    useState<boolean>();
  const [currentAbsenceId, setCurrentAbsenceId] = useState("");
  /**
   * Check if we have already loaded waiting periods for the given absence ID.
   * Notably, we don't care if we already have waiting periods loaded as they
   * could be for a different absence ID. Additionally, if we've already loaded
   * waiting periods for a given absence ID and there were none returned, we
   * shouldn't need to load them again.
   * @param absenceId
   * @returns True if the waiting periods stored in state are the ones for the
   * given absence ID
   */
  const hasLoadedWaitingPeriods = (absenceId: string) =>
    absenceId === currentAbsenceId;

  // Load waiting periods from API
  const loadWaitingPeriods = async (absenceId: string) => {
    if (isLoadingWaitingPeriods || hasLoadedWaitingPeriods(absenceId)) return;

    setIsLoadingWaitingPeriods(true);
    errorsLogic.clearErrors();
    try {
      const waitingPeriods = await waitingPeriodsApi.getWaitingPeriods(
        absenceId
      );
      setCurrentAbsenceId(absenceId);
      setWaitingPeriods(waitingPeriods);
    } catch (error) {
      errorsLogic.catchError(error);
      setCurrentAbsenceId("");
      setWaitingPeriods([]);
    } finally {
      setIsLoadingWaitingPeriods(false);
    }
  };

  return {
    loadWaitingPeriods,
    waitingPeriods,
    hasLoadedWaitingPeriods,
    isLoadingWaitingPeriods,
  };
};

export default useWaitingPeriodsLogic;
export type WaitingPeriodsLogic = ReturnType<typeof useWaitingPeriodsLogic>;
