import BaseApi from "./BaseApi";
import OAuthFlowStart from "src/models/OAuth";
import routes from "src/routes";

export default class OAuthApi extends BaseApi {
  get basePath() {
    return routes.api.oAuthStartFlow;
  }

  get namespace() {
    return "auth";
  }

  getOAuthFlowStart = async (
    authServer: string,
    flow: string,
    language: string,
    origin: string | null
  ) => {
    const { data } = await this.request<OAuthFlowStart>(
      "GET",
      `/${flow}/${authServer}/${language}?origin=${origin}`,
      undefined,
      {
        excludeAuthHeader: true,
      }
    );
    // console.log(data);

    return Promise.resolve({
      lmgAuth: new OAuthFlowStart(data),
    });
  };
}
