import dayjs from "dayjs";
import formatDate from "src/utils/formatDate";
import isBlank from "src/utils/isBlank";

/**
 * Managed requirements primarily track the status of a claim's info request process.
 * Also referred to as an "Outstanding Requirement" in the FINEOS interface.
 */
export interface ManagedRequirement {
  category: string;
  created_at: string;
  follow_up_date: string | null;
  responded_at: string | null;
  status: "Open" | "Complete" | "Suppressed";
  type: string;
}

/**
 * @returns Follow-up date of the "latest due" managed requirement, regardless of status.
 */
export function getLatestFollowUpDate(
  managedRequirements: ManagedRequirement[]
): string | null {
  const followUpDates = managedRequirements
    .map((managedRequirement) => managedRequirement.follow_up_date)
    .sort()
    .reverse();

  if (followUpDates.length === 0) return null;
  return followUpDates[0];
}

/**
 * @returns The "soonest due" Open managed requirement.
 */
export function getSoonestReviewableManagedRequirement(
  managedRequirements: ManagedRequirement[]
): ManagedRequirement | null {
  const today = dayjs().format("YYYY-MM-DD");
  const reviewableManagedRequirements = managedRequirements
    .filter(
      (managedRequirement) =>
        managedRequirement.status === "Open" &&
        managedRequirement.follow_up_date &&
        managedRequirement.follow_up_date >= today
    )
    .sort((a, b) => dayjs(a.follow_up_date).diff(dayjs(b.follow_up_date)));

  if (reviewableManagedRequirements.length === 0) return null;
  return reviewableManagedRequirements[0];
}

/**
 * @returns Follow-up date of the "soonest due" managed requirement.
 * Formatted for display to the user if `formattedDate` is `true`.
 */
export function getSoonestReviewableFollowUpDate(
  managedRequirements: ManagedRequirement[],
  formattedDate = true
): string | null {
  const closestReviewableManagedRequirement =
    getSoonestReviewableManagedRequirement(managedRequirements);

  if (!closestReviewableManagedRequirement) return null;
  const followUpDate = closestReviewableManagedRequirement.follow_up_date;

  if (formattedDate) {
    return formatDate(followUpDate).short();
  }
  return followUpDate;
}

export function lastReviewedAt(
  managedRequirements: ManagedRequirement[]
): string | undefined {
  if (managedRequirements?.length) {
    const nonBlankDates = managedRequirements
      .map((managedRequirement) => managedRequirement.responded_at)
      .filter((date): date is string => !isBlank(date))
      .sort();

    return nonBlankDates.length ? nonBlankDates[0] : undefined;
  }
}

export function wasPreviouslyReviewed(
  managedRequirements: ManagedRequirement[]
): boolean {
  if (managedRequirements?.length) {
    return managedRequirements.some((managedRequirement) => {
      // "Suppressed" indicates the managed requirement has been been closed, but doesn't mean it was *reviewed*
      return managedRequirement.status === "Complete";
    });
  }

  return false;
}

// This function exists because for extensions we need to make sure that both claimDetail.lastReviewedAt and claimDetail.latestFollowupDate
// come from the same (most recent) managed requirement. The other functions here don't allow that so the approach is that:
// - This function returns the most recently created, managed requirement
// - We then get the responded_at and follow_up_date for that requirement.
export function mostRecentlyCreatedManagedRequirement(
  managedRequirements: ManagedRequirement[]
): ManagedRequirement | undefined {
  const sortedManagedRequirements = sortNewToOldCreatedAt(managedRequirements);

  return sortedManagedRequirements[0];
}

export function managedRequirementDates(
  managedRequirement: ManagedRequirement | undefined
) {
  const employerReviewedOn = managedRequirement?.responded_at;
  const employerFollowUpDate = managedRequirement?.follow_up_date;
  const employerFollowUpDueOn = managedRequirement?.follow_up_date;

  return { employerReviewedOn, employerFollowUpDate, employerFollowUpDueOn };
}
function sortNewToOldCreatedAt(
  managedRequirements: ManagedRequirement[]
): ManagedRequirement[] {
  const periods = managedRequirements.slice(); // avoids mutating the original array
  return periods.sort((a, b) => {
    return a.created_at > b.created_at ? -1 : 1;
  });
}
