import {
  NullableQueryParams,
  createRouteWithQuery,
} from "src/utils/routeWithParams";
import { useMemo, useState } from "react";

import { ErrorsLogic } from "./useErrorsLogic";
import { MMGToPortalLanguageMap } from "src/models/LanguageEnum";
import OAuthApi from "src/api/OAuthApi";
import OAuthFlowStart from "src/models/OAuth";
import OAuthTokenApi from "src/api/OAuthTokenApi";
import { PortalFlow } from "./usePortalFlow";
import assert from "assert";
import oidc from "src/services/openIdConnect";
import routes from "src/routes";
import tracker from "src/services/tracker";

const useAuthLogic = ({
  errorsLogic,
  portalFlow,
}: {
  errorsLogic: ErrorsLogic;
  portalFlow: PortalFlow;
}) => {
  const oAuthApi = useMemo(() => new OAuthApi(), []);
  const oAuthTokenApi = useMemo(() => new OAuthTokenApi(), []);

  // TODO (CP-872): Rather than setting default values for authLogic methods,
  // instead ensure they're always called with required string arguments

  /**
   * @property isLoggedIn - Whether the user is logged in or not, or null if logged in status has not been checked yet
   */
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);

  /**
   * Log out of the Portal
   * @param options.sessionTimedOut Whether the logout occurred automatically as a result of session timeout.
   */
  const logout = (options = { sessionTimedOut: false, isRedirect: true }) => {
    const { sessionTimedOut } = options;
    let { isRedirect } = options;

    const params: NullableQueryParams = {};
    if (sessionTimedOut) {
      params["session-timed-out"] = "true";
      if (oidc.isOIDCAuthenticated()) {
        // persist this for the oauth return
        localStorage.setItem("IS_SESSION_TIMEOUT", "true");
      }
    }
    let redirectUrl = createRouteWithQuery(routes.auth.oAuthStart, params);

    try {
      tracker.trackEvent("signOut");
      const oidcLogoutUrl = oidc.endSessionUrl.get();
      oidc.signOut();
      if (oidcLogoutUrl) {
        // only grab the redirect URL here
        // do the redirect later after tracker actions are complete
        redirectUrl = oidcLogoutUrl;
        isRedirect = true;
      }
      tracker.markFetchRequestEnd();
    } catch (error) {
      tracker.noticeError(error);
    }

    if (isRedirect) {
      window.location.assign(redirectUrl);
    } else {
      // setting this triggers a react state refresh
      // it's not necessary if we're doing a full page redirect
      setIsLoggedIn(false);
    }
  };

  const getOAuthFlowStart = async (
    authServer: string,
    flow: string,
    origin: string | null
  ) => {
    try {
      let language = window.Localize ? window.Localize.getLanguage() : "en";
      // MMG returns some alternate language codes, check map and convert if necessary
      const mapping = Object.entries(MMGToPortalLanguageMap).find(
        ([_, val]) => val === language
      );
      if (mapping) {
        language = mapping[0];
      }
      const data = await oAuthApi.getOAuthFlowStart(
        authServer,
        flow,
        (language ?? "en").toString(),
        origin
      );
      return data;
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  const getOAuthToken = async (
    oAuthFlowRequest: OAuthFlowStart,
    code: string,
    state: string
  ) => {
    // let error raise, it will be handled later
    const data = await oAuthTokenApi.getOAuthToken(
      oAuthFlowRequest,
      code,
      state
    );
    return data;
  };

  /**
   * Check current session for current user info. If user is logged in,
   * set isLoggedIn to true or false depending on whether the user is logged in.
   * If the user is not logged in, redirect the user to the login page.
   */
  const requireLogin = () => {
    let tempIsLoggedIn = isLoggedIn;
    if (isLoggedIn === null) {
      // Check if the user is logged in with OAuth token
      tempIsLoggedIn = oidc.isOIDCAuthenticated();

      setIsLoggedIn(tempIsLoggedIn);
    }

    assert(tempIsLoggedIn !== null);

    // TODO (CP-733): Update this comment once we move logout functionality into this module
    // Note that although we don't yet have a logout function that sets isLoggedIn to false,
    // the logout (signOut) functionality in AuthNav.js forces a page reload which will
    // reset React in-memory state and set isLoggedIn back to null.

    if (tempIsLoggedIn) return;
    if (!tempIsLoggedIn && !portalFlow.pathname.match(routes.auth.oAuthStart)) {
      const { pathWithParams } = portalFlow;

      portalFlow.goTo(routes.auth.oAuthStart, { next: pathWithParams });
    }
  };

  return {
    getOAuthFlowStart,
    getOAuthToken,
    logout,
    isLoggedIn,
    requireLogin,
  };
};

export default useAuthLogic;
export type AuthLogic = ReturnType<typeof useAuthLogic>;
