import React, { useEffect } from "react";
import withUser, { WithUserProps } from "./withUser";

import BenefitYear from "src/models/BenefitYear";

export interface WithBenefitYearsProps extends WithUserProps {
  currentBenefitYear: BenefitYear | null;
  nextBenefitYear: BenefitYear | null;
  latestBenefitYear: BenefitYear | null;
}

function withBenefitYears<T extends WithBenefitYearsProps>(
  Component: React.ComponentType<T>
) {
  const ComponentWithBenefitYears = (props: T) => {
    const { appLogic } = props;
    const { loadBenefitYears, hasLoadedBenefitYears } = appLogic.benefitYears;
    const shouldLoad = !hasLoadedBenefitYears();

    useEffect(() => {
      if (shouldLoad) {
        loadBenefitYears();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldLoad]);

    const { currentBenefitYear, nextBenefitYear } =
      appLogic.benefitYears.getCurrentAndNextBenefitYear();
    const latestBenefitYear = appLogic.benefitYears.getLatestBenefitYear();

    return (
      <Component
        {...(props as T)}
        currentBenefitYear={currentBenefitYear}
        nextBenefitYear={nextBenefitYear}
        latestBenefitYear={latestBenefitYear}
      />
    );
  };

  return withUser(ComponentWithBenefitYears);
}

export default withBenefitYears;
