import withEmployerExemptionsApplication, {
  WithEmployerExemptionsApplicationProps,
} from "src/hoc/withEmployerExemptionsApplication";

import Fieldset from "src/components/core/Fieldset";
import FormLabel from "src/components/core/FormLabel";
import InputText from "src/components/core/InputText";
import PageNotFound from "src/components/PageNotFound";
import QuestionPage from "src/components/QuestionPage";
import React from "react";
import { Trans } from "react-i18next";
import { isFeatureEnabled } from "src/services/featureFlags";
import useFormState from "src/hooks/useFormState";
import useFunctionalInputProps from "src/hooks/useFunctionalInputProps";
import { useTranslation } from "src/locales/i18n";

/* 
prefix each field 'exemptionRequest.' this is needed in order for the employer 
exemptions progress page to display correctly
*/
export const fields = [
  "exemptionRequest.contact_first_name",
  "exemptionRequest.contact_last_name",
  "exemptionRequest.contact_title",
  "exemptionRequest.contact_phone.phone_number",
  "exemptionRequest.contact_email_address",
];

export const EmployerExemptionsContactDetails = (
  props: WithEmployerExemptionsApplicationProps
) => {
  const { appLogic, user, exemptionRequest } = props;
  const { t } = useTranslation();
  const { user_leave_administrators: user_exemption_administrator } = user;
  const { formState, updateFields } = useFormState({
    contact_first_name: exemptionRequest.contact_first_name || undefined,
    contact_last_name: exemptionRequest.contact_last_name || undefined,
    contact_title: exemptionRequest.contact_title || undefined,
    contact_phone: {
      phone_number: exemptionRequest.contact_phone?.phone_number
        ? exemptionRequest.contact_phone.phone_number
        : undefined,
    },
    contact_email_address: exemptionRequest.contact_email_address || undefined,
  });

  // TODO (PFMLPB-19806): removal of feature flag
  const isEnableEmployerExemptionsPortal = isFeatureEnabled(
    "enableEmployerExemptionsPortal"
  );

  const handleSave = () =>
    appLogic.employerExemptionsApplication.update(
      exemptionRequest.employer_exemption_application_id,
      formState
    );

  const getFunctionalInputProps = useFunctionalInputProps({
    errors: appLogic.errors,
    formState,
    updateFields,
  });

  if (!isEnableEmployerExemptionsPortal) {
    return <PageNotFound />;
  }
  return (
    <React.Fragment>
      <QuestionPage
        title={t("pages.employersExemptionsContactDetails.title")}
        headingPrefix={
          <Trans
            i18nKey="shared.employerExemptions.employerEIN"
            values={{
              employer_fein: user_exemption_administrator[0].employer_fein,
            }}
          />
        }
        onSave={handleSave}
      >
        <Fieldset>
          <FormLabel
            component="legend"
            hint={
              <Trans
                i18nKey={
                  "pages.employersExemptionsContactDetails.sectionLabelHint"
                }
              />
            }
          >
            {t("pages.employersExemptionsContactDetails.sectionLabel")}
          </FormLabel>
          <InputText
            {...getFunctionalInputProps("contact_first_name")}
            label={t(
              "pages.employersExemptionsContactDetails.contact_first_name"
            )}
            smallLabel={true}
          />
          <InputText
            {...getFunctionalInputProps("contact_last_name")}
            label={t(
              "pages.employersExemptionsContactDetails.contact_last_name"
            )}
            smallLabel={true}
          />
          <InputText
            {...getFunctionalInputProps("contact_title")}
            label={t("pages.employersExemptionsContactDetails.contact_title")}
            smallLabel={true}
          />
          <InputText
            {...getFunctionalInputProps("contact_phone.phone_number")}
            label={t("pages.employersExemptionsContactDetails.contact_phone")}
            smallLabel={true}
            mask="phone"
            pii={true}
          />
          <InputText
            {...getFunctionalInputProps("contact_email_address")}
            label={t(
              "pages.employersExemptionsContactDetails.contact_email_address"
            )}
            smallLabel={true}
          />
        </Fieldset>
      </QuestionPage>
    </React.Fragment>
  );
};

export default withEmployerExemptionsApplication(
  EmployerExemptionsContactDetails
);
