import BaseApi from "./BaseApi";
import WaitingPeriod from "src/models/WaitingPeriod";
import routes from "src/routes";

export default class WaitingPeriodsApi extends BaseApi {
  get basePath() {
    return routes.api.waitingPeriods;
  }

  get namespace() {
    return "waitingPeriods";
  }

  /**
   * Fetches a list of waiting periods for a claim given a FINEOS absence ID
   */
  getWaitingPeriods = async (absenceId: string) => {
    const { data } = await this.request<{
      waiting_periods: WaitingPeriod[] | null;
    }>("POST", "search", {
      terms: { absence_case_id: absenceId },
    });

    // TODO(PFMLPB-12934): ticket to fix behavior where some array vals are null
    const waitingPeriods = data.waiting_periods
      ?.filter((waitingPeriod) => waitingPeriod)
      .map((waitingPeriod) => new WaitingPeriod(waitingPeriod));
    return waitingPeriods;
  };
}
