import EmployerBenefit, {
  EmployerBenefitType,
} from "src/models/EmployerBenefit";
import { Trans, useTranslation } from "react-i18next";
import { cloneDeep, get, pick } from "lodash";
import withBenefitsApplication, {
  WithBenefitsApplicationProps,
} from "src/hoc/withBenefitsApplication";

import Alert from "src/components/core/Alert";
import ConditionalContent from "src/components/ConditionalContent";
import Fieldset from "src/components/core/Fieldset";
import Heading from "src/components/core/Heading";
import InputChoiceGroup from "src/components/core/InputChoiceGroup";
import InputDate from "src/components/core/InputDate";
import LeaveDatesAlert from "src/components/LeaveDatesAlert";
import QuestionPage from "src/components/QuestionPage";
import React from "react";
import RepeatableFieldset from "src/components/core/RepeatableFieldset";
import dayjs from "dayjs";
import { formatDateFull } from "src/utils/formatDate";
import { isFeatureEnabled } from "src/services/featureFlags";
import useFormState from "src/hooks/useFormState";
import useFunctionalInputProps from "src/hooks/useFunctionalInputProps";

export const fields = [
  "claim.employer_benefits",
  "claim.has_employer_benefits",
  "claim.employer_benefits[*].benefit_end_date",
  "claim.employer_benefits[*].benefit_start_date",
  "claim.employer_benefits[*].benefit_type",
];

export const EmployerBenefits = (props: WithBenefitsApplicationProps) => {
  const { appLogic, claim } = props;
  const { t } = useTranslation();
  const limit = 6;
  const {
    isIntermittent,
    isReducedSchedule,
    employer_fein,
    leaveEndDate,
    leaveStartDate,
  } = claim;

  const showNewEmployerBenefitWording = isFeatureEnabled(
    "enableNewEmployerBenefitWording"
  );

  const pfmlLeaveDayLength = dayjs(leaveEndDate).diff(
    dayjs(leaveStartDate),
    "day"
  );
  const isShortPFMLLeave = pfmlLeaveDayLength <= 7;

  const lastWaitingPeriodDayFormatted = formatDateFull(
    dayjs(leaveStartDate).add(6, "day").format()
  );

  const dayAfterWaitingPeriodFormatted = formatDateFull(
    dayjs(lastWaitingPeriodDayFormatted).add(1, "day").format()
  );
  const leaveEndDateFormatted = formatDateFull(leaveEndDate);

  // show slightly different content if the pfml leave is continuous vs (reduced or intermittent)
  const isReducedOrIntermittent = isReducedSchedule || isIntermittent;

  const initialEntries = pick(props, fields).claim || {
    employer_benefits: [],
  };
  // If the claim doesn't have any employer benefits pre-populate the first one so that
  // it renders in the RepeatableFieldset below
  if (initialEntries.employer_benefits.length === 0) {
    initialEntries.employer_benefits = [
      new EmployerBenefit({ is_full_salary_continuous: true }),
    ];
  }
  const { formState, updateFields } = useFormState(initialEntries);

  const employer_benefits: EmployerBenefit[] = get(
    formState,
    "employer_benefits"
  );

  const handleSave = () => {
    const benefitData = cloneDeep(formState);

    // If we select 'No' - make sure to clear any filled out benefits
    // which will clear the employer_benefit db table entry
    if (!benefitData.has_employer_benefits && benefitData.employer_benefits) {
      benefitData.employer_benefits = [];
      updateFields(benefitData);
    }
    return appLogic.benefitsApplications.update(
      claim.application_id,
      benefitData
    );
  };

  const handleAddClick = () => {
    // Add a new blank entry
    const updatedEntries = employer_benefits.concat([
      new EmployerBenefit({ is_full_salary_continuous: true }),
    ]);
    updateFields({ employer_benefits: updatedEntries });
  };

  const handleRemoveClick = (entry: EmployerBenefit, index: number) => {
    const updatedBenefits = [...employer_benefits];
    updatedBenefits.splice(index, 1);
    updateFields({ employer_benefits: updatedBenefits });
  };

  const getFunctionalInputProps = useFunctionalInputProps({
    errors: appLogic.errors,
    formState,
    updateFields,
  });

  const render = (entry: EmployerBenefit, index: number) => {
    return (
      <EmployerBenefitCard
        entry={entry}
        getFunctionalInputProps={getFunctionalInputProps}
        index={index}
        updateFields={updateFields}
        hasEmployerBenefits={formState.has_employer_benefits === true}
      />
    );
  };

  const noHint = (
    <ConditionalContent visible={formState.has_employer_benefits === false}>
      <Alert state="info" role="alert" slim className="margin-left-neg-1">
        {t("pages.claimsEmployerBenefits.choiceNoAlert")}
      </Alert>
    </ConditionalContent>
  );

  const yesHint = (
    <ConditionalContent visible={formState.has_employer_benefits === true}>
      <Alert state="warning" role="alert" slim className="margin-left-neg-1">
        {showNewEmployerBenefitWording
          ? t("pages.claimsEmployerBenefits.choiceYesAlertV2")
          : t("pages.claimsEmployerBenefits.choiceYesAlert")}
      </Alert>
    </ConditionalContent>
  );

  const getQuestionTitle = () => {
    const getTitle = () => {
      return isShortPFMLLeave ? (
        <Trans
          i18nKey="pages.claimsEmployerBenefits.sectionLabelShortLeaveEdgeCase"
          values={{
            employer_fein,
          }}
        />
      ) : (
        <Trans
          i18nKey="pages.claimsEmployerBenefits.sectionLabel"
          values={{
            employer_fein,
            leaveEndDateFormatted,
            dayAfterWaitingPeriodFormatted,
            dateIntervalText: isReducedOrIntermittent
              ? "on any days between"
              : "between",
          }}
        />
      );
    };

    return (
      <Heading level="2" size="1">
        <div>{getTitle()}</div>
      </Heading>
    );
  };

  return (
    <QuestionPage
      title={t("pages.claimsEmployerBenefits.title")}
      onSave={handleSave}
    >
      {getQuestionTitle()}
      <br />
      <LeaveDatesAlert
        startDate={claim.leaveStartDate}
        endDate={claim.leaveEndDate}
        showWaitingDayPeriod={!isIntermittent}
        applicationSplit={claim.computed_application_split}
        useNeutralBackgroundColor={false}
        useFullDates
      />
      <InputChoiceGroup
        {...getFunctionalInputProps("has_employer_benefits")}
        choices={[
          {
            checked: formState.has_employer_benefits === true,
            label: (
              <div>
                {t("pages.claimsEmployerBenefits.choiceYes")}
                <span
                  className="usa-radio__label-description text-base-dark margin-bottom-2 margin-top-1"
                  dangerouslySetInnerHTML={{
                    __html: showNewEmployerBenefitWording
                      ? t("pages.claimsEmployerBenefits.choiceYesHintV2")
                      : t("pages.claimsEmployerBenefits.choiceYesHint"),
                  }}
                />
              </div>
            ),
            hint: yesHint,
            value: "true",
          },
          {
            checked: formState.has_employer_benefits === false,
            label: (
              <div>
                {t("pages.claimsEmployerBenefits.choiceNo")}
                <span className="usa-radio__label-description text-base-dark margin-bottom-2 margin-top-1">
                  {t("pages.claimsEmployerBenefits.choiceNoHint")}
                </span>
              </div>
            ),
            hint: noHint,
            value: "false",
          },
        ]}
        label={""}
        type="radio"
      />
      <div className="margin-top-2">
        <ConditionalContent visible={formState.has_employer_benefits === true}>
          <RepeatableFieldset
            addButtonLabel={t("pages.claimsEmployerBenefits.addButton")}
            entries={employer_benefits}
            subHeading={
              <Trans
                i18nKey="pages.claimsEmployerBenefits.cardSubHeading"
                values={{ employer_fein }}
              />
            }
            subHeadingDescription={t(
              "pages.claimsEmployerBenefits.cardSubHeadingDescription"
            )}
            onAddClick={handleAddClick}
            onRemoveClick={handleRemoveClick}
            removeButtonLabel={t("pages.claimsEmployerBenefits.removeButton")}
            render={render}
            limit={limit}
            limitMessage={
              <div title="limit message">
                <Trans
                  i18nKey="pages.claimsEmployerBenefits.limitMessage"
                  values={{ limit }}
                />
              </div>
            }
          />
        </ConditionalContent>
      </div>
    </QuestionPage>
  );
};

interface EmployerBenefitCardProps {
  index: number;
  entry: EmployerBenefit;
  hasEmployerBenefits: boolean;
  getFunctionalInputProps: ReturnType<typeof useFunctionalInputProps>;
  updateFields: (fields: { [fieldName: string]: unknown }) => void;
}

/**
 * Helper component for rendering employer benefit cards
 */
export const EmployerBenefitCard = (props: EmployerBenefitCardProps) => {
  const { t } = useTranslation();
  const {
    entry,
    getFunctionalInputProps,
    index,
    updateFields,
    hasEmployerBenefits,
  } = props;
  const clearField = (fieldName: string) => updateFields({ [fieldName]: null });

  const showNewEmployerBenefitWording = isFeatureEnabled(
    "enableNewEmployerBenefitWording"
  );

  // Since we are not passing the formState to the benefit card,
  // get the field value from the entry by removing the field path
  const getEntryField = (fieldName: string) => {
    return get(
      entry,
      fieldName.replace(`employer_benefits[${index}].`, ""),
      ""
    );
  };
  const selectedType = entry.benefit_type;

  const benefitTypeChoiceKeys: Array<keyof typeof EmployerBenefitType> = [
    "shortTermDisability",
    "permanentDisability",
    "familyOrMedicalLeave",
    showNewEmployerBenefitWording ? "paidTimeOff" : "imNotSure",
  ];

  const getHint = (benefitTypeKey: string) => {
    const benefitTypesWithHints = [
      "shortTermDisability",
      "familyOrMedicalLeave",
      showNewEmployerBenefitWording ? "paidTimeOff" : null,
    ];
    return benefitTypesWithHints.includes(benefitTypeKey)
      ? t("pages.claimsEmployerBenefits.choiceHint", {
          context: benefitTypeKey,
        })
      : null;
  };

  return (
    <React.Fragment>
      <ConditionalContent
        clearField={clearField}
        getField={getEntryField}
        updateFields={updateFields}
        visible={hasEmployerBenefits === true}
      >
        <InputChoiceGroup
          {...getFunctionalInputProps(
            `employer_benefits[${index}].benefit_type`
          )}
          choices={benefitTypeChoiceKeys.map((benefitTypeKey) => {
            const showNewWording =
              showNewEmployerBenefitWording && benefitTypeKey === "paidTimeOff";

            return {
              checked: selectedType === EmployerBenefitType[benefitTypeKey],
              label: (
                <Trans
                  i18nKey="pages.claimsEmployerBenefits.choiceLabel"
                  tOptions={{
                    context: showNewWording
                      ? "paidTimeOffEnableNewEmployerBenefitWording"
                      : benefitTypeKey,
                  }}
                  components={{ strong: <strong /> }}
                />
              ),
              hint: getHint(benefitTypeKey),
              value: EmployerBenefitType[benefitTypeKey],
            };
          })}
          label={t("pages.claimsEmployerBenefits.typeLabel")}
          type="radio"
          smallLabel
        />
        <Fieldset>
          <InputDate
            {...getFunctionalInputProps(
              `employer_benefits[${index}].benefit_start_date`
            )}
            label={t("pages.claimsEmployerBenefits.startDateLabel")}
            example={t("components.form.dateInputExample")}
            dayLabel={t("components.form.dateInputDayLabel")}
            monthLabel={t("components.form.dateInputMonthLabel")}
            yearLabel={t("components.form.dateInputYearLabel")}
            smallLabel
          />
          <InputDate
            {...getFunctionalInputProps(
              `employer_benefits[${index}].benefit_end_date`
            )}
            label={t("pages.claimsEmployerBenefits.endDateLabel")}
            example={t("components.form.dateInputExample")}
            dayLabel={t("components.form.dateInputDayLabel")}
            monthLabel={t("components.form.dateInputMonthLabel")}
            yearLabel={t("components.form.dateInputYearLabel")}
            smallLabel
          />
        </Fieldset>
      </ConditionalContent>
    </React.Fragment>
  );
};

export default withBenefitsApplication(EmployerBenefits);
