import { Trans, useTranslation } from "react-i18next";
import withAppeal, { WithAppealProps } from "src/hoc/withAppeal";

import Appeal from "src/models/Appeal";
import ExternalLink from "src/components/core/ExternalLink";
import InputChoiceGroup from "src/components/core/InputChoiceGroup";
import QuestionPage from "src/components/QuestionPage";
import React from "react";
import routes from "src/routes";
import useFormState from "src/hooks/useFormState";
import useFunctionalInputProps from "src/hooks/useFunctionalInputProps";

export const fields: Array<keyof Appeal> = ["appeal_representative_option"];

export const Representative = (props: WithAppealProps) => {
  const { appLogic, appeal } = props;
  const { t } = useTranslation();

  const { formState, updateFields } = useFormState(appeal);
  const getFunctionalInputProps = useFunctionalInputProps({
    errors: appLogic.errors,
    formState,
    updateFields,
  });

  const handleSave = async () =>
    await appLogic.appeals.update(appeal.appeal_id, formState);

  return (
    <QuestionPage
      onSave={handleSave}
      title={t("pages.appealsRepresentative.title")}
    >
      <InputChoiceGroup
        choices={[
          {
            label: t("pages.appealsRepresentative.choiceYes"),
            value: "Yes",
            checked: formState.appeal_representative_option === "Yes",
          },
          {
            label: t("pages.appealsRepresentative.choiceNo"),
            value: "No",
            checked: formState.appeal_representative_option === "No",
          },
          {
            label: t("pages.appealsRepresentative.choiceUnknown"),
            value: "Unknown",
            checked: formState.appeal_representative_option === "Unknown",
          },
        ]}
        label={t("pages.appealsRepresentative.label")}
        type="radio"
        hint={
          <Trans
            i18nKey="pages.appealsRepresentative.representativeHint"
            components={{
              "legal-help-link": (
                <ExternalLink href={routes.external.massgov.findingLegalHelp} />
              ),
            }}
          />
        }
        {...getFunctionalInputProps("appeal_representative_option")}
      />
      {formState.appeal_representative_option === "Yes" && (
        <p>{t("pages.appealsRepresentative.noticeOfAppearance")}</p>
      )}
    </QuestionPage>
  );
};

export default withAppeal(Representative);
