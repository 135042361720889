import IntermittentLeaveEpisode, {
  IntermittentLeaveResponse,
} from "src/models/IntermittentLeaveEpisode";

import BaseApi from "./BaseApi";
import routes from "src/routes";

interface IntermittentLeaveApiReturnValue {
  leave_episodes: IntermittentLeaveResponse[];
}
export default class IntermittentLeaveApi extends BaseApi {
  get basePath(): string {
    return routes.api.intermittentLeave;
  }

  get namespace(): string {
    return "intermittent_leave_reporting";
  }

  postIntermittentLeaveEpisode = async (
    absence_case_id: string,
    optionalData: Partial<IntermittentLeaveEpisode> | undefined
  ) => {
    const { data, warnings } =
      await this.request<IntermittentLeaveApiReturnValue>(
        "POST",
        `?absence_case_id=${absence_case_id}`,
        optionalData ?? {}
      );
    return {
      leave_episodes: data.leave_episodes,
      warnings,
    };
  };
}
