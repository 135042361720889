import withEmployerExemptionsApplication, {
  WithEmployerExemptionsApplicationProps,
} from "src/hoc/withEmployerExemptionsApplication";

import ExternalLink from "src/components/core/ExternalLink";
import Fieldset from "src/components/core/Fieldset";
import FormLabel from "src/components/core/FormLabel";
import InputChoiceGroup from "src/components/core/InputChoiceGroup";
import InputNumber from "src/components/core/InputNumber";
import InputText from "src/components/core/InputText";
import InsurancePlanInputDate from "./InsurancePlanInputDate";
import QuestionPage from "src/components/QuestionPage";
import React from "react";
import { Trans } from "react-i18next";
import routes from "src/routes";
import useFormState from "src/hooks/useFormState";
import useFunctionalInputProps from "src/hooks/useFunctionalInputProps";
import { useTranslation } from "src/locales/i18n";

export const fields = [
  "exemptionRequest.self_insured.has_obtained_surety_bond",
  "exemptionRequest.self_insured.surety_bond_amount",
  "exemptionRequest.self_insured.surety_company",
  "exemptionRequest.insurance_plan_effective_at",
  "exemptionRequest.insurance_plan_expires_at",
];

export const EmployerExemptionsSelfInsuredInsuranceDetails = (
  props: WithEmployerExemptionsApplicationProps
) => {
  const { appLogic, exemptionRequest } = props;
  const { t } = useTranslation();
  const { formState, updateFields } = useFormState({
    self_insured: {
      has_obtained_surety_bond:
        exemptionRequest.self_insured.has_obtained_surety_bond ?? "",
      surety_bond_amount:
        exemptionRequest.self_insured.surety_bond_amount ?? "",
      surety_company: exemptionRequest.self_insured.surety_company ?? "",
    },
    insurance_plan_effective_at: exemptionRequest.insurance_plan_effective_at,
    insurance_plan_expires_at: exemptionRequest.insurance_plan_expires_at,
  });

  const getFunctionalInputProps = useFunctionalInputProps({
    errors: appLogic.errors,
    formState,
    updateFields,
  });

  // save changes to the DB and include the values set on the parent page
  const handleSave = async () => {
    await appLogic.employerExemptionsApplication.update(
      exemptionRequest.employer_exemption_application_id,
      {
        ...formState,
        is_self_insured_plan: true,
        has_family_exemption: exemptionRequest.has_family_exemption,
        has_medical_exemption: exemptionRequest.has_medical_exemption,
      }
    );
  };

  return (
    <React.Fragment>
      <QuestionPage title={""} onSave={handleSave} displayBackButton={false}>
        <Fieldset>
          <FormLabel
            component="legend"
            hint={
              <Trans
                i18nKey="pages.employersExemptionsInsuranceDetails.selfInsuredSection_hint"
                components={{
                  "self-insured-bond-requirements-link": (
                    <ExternalLink
                      href={
                        routes.external.massgov
                          .selfInsuredPrivatePlanBondCoverageRequirements
                      }
                    />
                  ),
                }}
              />
            }
          >
            {""}
          </FormLabel>
          <InputChoiceGroup
            {...getFunctionalInputProps(
              "self_insured.has_obtained_surety_bond"
            )}
            smallLabel
            choices={[
              {
                checked:
                  formState.self_insured.has_obtained_surety_bond === true,
                label: t("pages.employersExemptionsInsuranceDetails.choiceYes"),
                value: "true",
              },
              {
                checked:
                  formState.self_insured.has_obtained_surety_bond === false,
                label: t("pages.employersExemptionsInsuranceDetails.choiceNo"),
                value: "false",
              },
            ]}
            type="radio"
            label={t(
              "pages.employersExemptionsInsuranceDetails.selfInsuredSectionObtainedSuretyBondQuestion"
            )}
          />
          <InputText
            {...getFunctionalInputProps("self_insured.surety_company")}
            label={t(
              "pages.employersExemptionsInsuranceDetails.selfInsuredSectionSuretyCompany_label"
            )}
            smallLabel
          />
          <InsurancePlanInputDate
            labelTranslationKey={
              "selfInsuredSectionSuretyBondEffectiveDate_label"
            }
            inputProps={getFunctionalInputProps("insurance_plan_effective_at")}
            value={formState.insurance_plan_effective_at}
          />
          <InputNumber
            {...getFunctionalInputProps("self_insured.surety_bond_amount")}
            label={t(
              "pages.employersExemptionsInsuranceDetails.selfInsuredSectionSuretyBondAmount_label"
            )}
            smallLabel
            valueType="integer"
          />
          <InsurancePlanInputDate
            labelTranslationKey={"selfInsuredSectionPolicyExpiryDate_label"}
            inputProps={getFunctionalInputProps("insurance_plan_expires_at")}
            value={formState.insurance_plan_expires_at}
          />
        </Fieldset>
      </QuestionPage>
    </React.Fragment>
  );
};

export default withEmployerExemptionsApplication(
  EmployerExemptionsSelfInsuredInsuranceDetails
);
