import { ValuesOf } from "types/common";

export const PhoneType = {
  cell: "Cell",
  phone: "Phone",
} as const;

export interface PhoneWithoutExtension {
  int_code: string | null;
  phone_number: string | null;
  phone_type: ValuesOf<typeof PhoneType> | null;
}

export interface PhoneWithExtension {
  int_code: string | null;
  phone_number: string | null;
  extension: string | null;
  phone_type: ValuesOf<typeof PhoneType> | null;
}
