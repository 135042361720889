import PreviousLeave, { PreviousLeaveReason } from "src/models/PreviousLeave";

import ConditionalContent from "./ConditionalContent";
import Details from "./core/Details";
import InputChoiceGroup from "./core/InputChoiceGroup";
import InputDate from "./core/InputDate";
import InputHours from "./core/InputHours";
import React from "react";
import { Trans } from "react-i18next";
import useFunctionalInputProps from "src/hooks/useFunctionalInputProps";
import { useTranslation } from "src/locales/i18n";

interface PreviousLeaveDetailsCardProps {
  previousLeave: PreviousLeave;
  index: number;
  currentEmployerFein: string;
  getFunctionalInputProps: ReturnType<typeof useFunctionalInputProps>;
}

/**
 * Component used to gather information from a claimant about a previous leave.
 */
const PreviousLeaveDetailsCard = (props: PreviousLeaveDetailsCardProps) => {
  const { previousLeave, index, currentEmployerFein, getFunctionalInputProps } =
    props;
  const { t } = useTranslation();

  // "Why did you need to take this leave?"
  const getLeaveReasonQuestion = () => {
    const getChoices = () => {
      // get the leave reasons that we want to display as choices
      // use the keys, because the values are full English sentence strings
      const leaveReasonsToDisplay = [
        "medical",
        "medicalHospital",
        "pregnancyHealthCondition",
        "pregnancy",
        "bonding",
        "care",
        "activeDutyFamily",
        "serviceMemberFamily",
      ];

      const leaveReasonEntriesToDisplay = leaveReasonsToDisplay.map((key) => [
        key,
        PreviousLeaveReason[key as keyof typeof PreviousLeaveReason],
      ]);

      return leaveReasonEntriesToDisplay.map(([key, value]) => ({
        label: t(`pages.claimsPreviousLeaves.questionLeaveReasonChoice_${key}`),
        value,
        checked: previousLeave.leave_reason === value,
      }));
    };

    return (
      <InputChoiceGroup
        {...getFunctionalInputProps(`previous_leaves[${index}].leave_reason`)}
        choices={getChoices()}
        label={t("pages.claimsPreviousLeaves.questionLeaveReasonLabel")}
        smallLabel
        type="radio"
      />
    );
  };

  const getYesNoChoices = (value: boolean | null) => {
    return [
      {
        checked: value === true,
        label: t("pages.claimsPreviousLeaves.choiceYes"),
        value: "true",
      },
      {
        checked: value === false,
        label: t("pages.claimsPreviousLeaves.choiceNo"),
        value: "false",
      },
    ];
  };

  // "When you took this leave, was it from this employer?"
  const getCurrentEmployerQuestion = () => {
    return (
      <InputChoiceGroup
        {...getFunctionalInputProps(
          `previous_leaves[${index}].is_for_current_employer`
        )}
        choices={getYesNoChoices(previousLeave.is_for_current_employer)}
        hint={t("pages.claimsPreviousLeaves.questionCurrentEmployerHint")}
        label={
          <Trans
            i18nKey="pages.claimsPreviousLeaves.questionCurrentEmployerLabel"
            values={{
              employerFein: currentEmployerFein,
            }}
          />
        }
        smallLabel
        type="radio"
      />
    );
  };

  const getInputDateQuestion = (inputProp: string, label: string) => {
    return (
      <InputDate
        {...getFunctionalInputProps(inputProp)}
        smallLabel
        label={t(label)}
        dayLabel={t("components.form.dateInputDayLabel")}
        monthLabel={t("components.form.dateInputMonthLabel")}
        yearLabel={t("components.form.dateInputYearLabel")}
      />
    );
  };

  // "What was the first day of this leave?"
  const getLeaveStartDateQuestion = () => {
    return getInputDateQuestion(
      `previous_leaves[${index}].leave_start_date`,
      "pages.claimsPreviousLeaves.questionLeaveStartDateLabel"
    );
  };

  // "What was the last day of this leave?"
  const getLeaveEndDateQuestion = () => {
    return getInputDateQuestion(
      `previous_leaves[${index}].leave_end_date`,
      "pages.claimsPreviousLeaves.questionLeaveEndDateLabel"
    );
  };

  // "Were you completely off work from the start to the end of this leave?"
  const getIsContinuousQuestion = () => {
    return (
      <InputChoiceGroup
        {...getFunctionalInputProps(`previous_leaves[${index}].is_continuous`)}
        choices={getYesNoChoices(previousLeave.is_continuous)}
        label={t("pages.claimsPreviousLeaves.questionIsContinuousLabel")}
        smallLabel
        type="radio"
      />
    );
  };

  // "How many hours per week did you normally work prior to taking this leave?"
  // Conditional on `is_continuous` -> `false`
  const getWorkedPerWeekMinutesQuestion = () => {
    // The translation string keys in this question are getting pretty lengthy -
    // break the prefix off into a separate string so that the React fragments are more readable.
    // This pattern could be applied across the whole file, but it's not a pattern that we use more widely,
    // so keep it local for now.
    const prefix = "pages.claimsPreviousLeaves";

    const getHint = () => {
      return (
        <React.Fragment>
          <Details
            label={t(prefix + ".questionWorkedPerWeekMinutesHintDetailsLabel")}
          >
            {t(prefix + ".questionWorkedPerWeekMinutesHintDetails")}
          </Details>
        </React.Fragment>
      );
    };

    const getQuestion = () => {
      return (
        <InputHours
          {...getFunctionalInputProps(
            `previous_leaves[${index}].worked_per_week_minutes`,
            { fallbackValue: null }
          )}
          label={t(prefix + ".questionWorkedPerWeekMinutesLabel")}
          smallLabel
          hint={getHint()}
          hoursLabel={t(prefix + ".hoursLabel")}
          minutesLabel={t(prefix + ".minutesLabel")}
          minutesIncrement={15}
        />
      );
    };

    return (
      <ConditionalContent visible={previousLeave.is_continuous === false}>
        {getQuestion()}
      </ConditionalContent>
    );
  };

  // "What was the total number of hours you took off?"
  // Conditional on `is_continuous` -> `false`
  const getLeaveMinutesQuestion = () => {
    const getQuestion = () => {
      return (
        <InputHours
          {...getFunctionalInputProps(
            `previous_leaves[${index}].leave_minutes`,
            { fallbackValue: null }
          )}
          label={t("pages.claimsPreviousLeaves.questionLeaveMinutesLabel")}
          smallLabel
          hoursLabel={t("pages.claimsPreviousLeaves.hoursLabel")}
          minutesLabel={t("pages.claimsPreviousLeaves.minutesLabel")}
          hint={t("pages.claimsPreviousLeaves.questionLeaveMinutesHint")}
          minutesIncrement={15}
        />
      );
    };

    return (
      <ConditionalContent visible={previousLeave.is_continuous === false}>
        {getQuestion()}
      </ConditionalContent>
    );
  };

  return (
    <React.Fragment>
      {getLeaveReasonQuestion()}
      {getCurrentEmployerQuestion()}
      {getLeaveStartDateQuestion()}
      {getLeaveEndDateQuestion()}
      {getIsContinuousQuestion()}
      {getWorkedPerWeekMinutesQuestion()}
      {getLeaveMinutesQuestion()}
    </React.Fragment>
  );
};

export default PreviousLeaveDetailsCard;
