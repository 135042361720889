import withEmployerExemptionsApplication, {
  WithEmployerExemptionsApplicationProps,
} from "src/hoc/withEmployerExemptionsApplication";

import ExternalLink from "src/components/core/ExternalLink";
import FormLabel from "src/components/core/FormLabel";
import InputChoiceGroup from "src/components/core/InputChoiceGroup";
import PageNotFound from "src/components/PageNotFound";
import QuestionPage from "src/components/QuestionPage";
import React from "react";
import { Trans } from "react-i18next";
import { isFeatureEnabled } from "src/services/featureFlags";
import routes from "src/routes";
import useFormState from "src/hooks/useFormState";
import useFunctionalInputProps from "src/hooks/useFunctionalInputProps";
import { useTranslation } from "src/locales/i18n";

/* 
prefix each field 'exemptionRequest.' this is needed for the employer 
exemptions progress page to display correctly
*/
export const fields = ["exemptionRequest.has_third_party_administrator"];

export const EmployerExemptionsThirdPartyAdministrator = (
  props: WithEmployerExemptionsApplicationProps
) => {
  const { appLogic, exemptionRequest, user } = props;
  const { t } = useTranslation();
  const { user_leave_administrators } = user;

  const { formState, updateFields } = useFormState({
    has_third_party_administrator:
      exemptionRequest.has_third_party_administrator,
  });

  // TODO (PFMLPB-19806): removal of feature flag
  const isEnableEmployerExemptionsPortal = isFeatureEnabled(
    "enableEmployerExemptionsPortal"
  );

  const handleSave = async () => {
    await appLogic.employerExemptionsApplication.update(
      exemptionRequest.employer_exemption_application_id,
      formState
    );
  };

  const getFunctionalInputProps = useFunctionalInputProps({
    errors: appLogic.errors,
    formState,
    updateFields,
  });

  if (!isEnableEmployerExemptionsPortal) {
    return <PageNotFound />;
  }

  return (
    <React.Fragment>
      <QuestionPage
        title={t("pages.employersExemptionsThirdPartyAdministrator.title")}
        headingPrefix={
          <Trans
            i18nKey="shared.employerExemptions.employerEIN"
            values={{
              employer_fein: user_leave_administrators[0].employer_fein,
            }}
          />
        }
        onSave={handleSave}
      >
        <FormLabel
          component="legend"
          hint={
            <Trans
              i18nKey="pages.employersExemptionsThirdPartyAdministrator.sectionLabelHint"
              components={{
                "third-party-administrators-link": (
                  <ExternalLink
                    href={routes.external.massgov.thirdPartyAdministrators}
                  />
                ),
              }}
            />
          }
        >
          {t("pages.employersExemptionsThirdPartyAdministrator.sectionLabel")}
        </FormLabel>
        <InputChoiceGroup
          {...getFunctionalInputProps("has_third_party_administrator")}
          smallLabel
          choices={[
            {
              checked: formState.has_third_party_administrator === true,
              label: t(
                "pages.employersExemptionsThirdPartyAdministrator.choiceYes"
              ),
              value: "true",
            },
            {
              checked: formState.has_third_party_administrator === false,
              label: t(
                "pages.employersExemptionsThirdPartyAdministrator.choiceNo"
              ),
              value: "false",
            },
          ]}
          type="radio"
          label={t(
            "pages.employersExemptionsThirdPartyAdministrator.hasThirdPartyAdministratorQuestion"
          )}
        />
      </QuestionPage>
    </React.Fragment>
  );
};

export default withEmployerExemptionsApplication(
  EmployerExemptionsThirdPartyAdministrator
);
