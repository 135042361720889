import Appeal from "src/models/Appeal";
import { GuardFn } from "./index";
import { fields as interpreterFields } from "src/pages/applications/appeals/interpreter";
import { fields as noticeCirumstancesFields } from "src/pages/applications/appeals/notice-circumstances";
import { fields as noticeDateFields } from "src/pages/applications/appeals/notice-date";
import { fields as noticeReadFields } from "src/pages/applications/appeals/notice-read";
import { fields as phoneFields } from "src/pages/applications/appeals/phone";
import { fields as reasonFields } from "src/pages/applications/appeals/reason";
import { fields as reprentativeFields } from "src/pages/applications/appeals/representative";
import routes from "src/routes";

export interface AppealFlowContext {
  appeal?: Appeal;
  hasDocuments: boolean;
}

export const guards: { [guardName: string]: GuardFn } = {
  isMoreThan10DaysAfterDecision: ({ appeal }) => {
    return !!appeal?.computed_is_more_than_ten_days_past_decision;
  },
  haveAppealDocumentsToUpload: ({ hasDocuments }) => hasDocuments === true,
};

export default {
  states: {
    [routes.applications.appeals.start]: {
      on: {
        // this should be the same page as the one the Review page routes to for editing details,
        // and the same as CONTINUE_APPEAL:
        CONTINUE: routes.applications.appeals.reason,
      },
    },
    [routes.applications.appeals.reason]: {
      meta: {
        fields: reasonFields,
      },
      on: {
        CONTINUE: routes.applications.appeals.phone,
      },
    },
    [routes.applications.appeals.phone]: {
      meta: {
        fields: phoneFields,
      },
      on: {
        CONTINUE: routes.applications.appeals.interpreter,
      },
    },
    [routes.applications.appeals.interpreter]: {
      meta: {
        fields: interpreterFields,
      },
      on: {
        CONTINUE: routes.applications.appeals.representative,
      },
    },
    [routes.applications.appeals.representative]: {
      meta: {
        fields: reprentativeFields,
      },
      on: {
        CONTINUE: routes.applications.appeals.noticeDate,
      },
    },
    [routes.applications.appeals.noticeDate]: {
      meta: {
        fields: noticeDateFields,
      },
      on: {
        CONTINUE: [
          {
            cond: "isMoreThan10DaysAfterDecision",
            target: routes.applications.appeals.noticeCircumstances,
          },
          {
            target: routes.applications.appeals.review,
          },
        ],
      },
    },
    [routes.applications.appeals.noticeCircumstances]: {
      meta: {
        fields: noticeCirumstancesFields,
      },
      on: {
        CONTINUE: routes.applications.appeals.noticeRead,
      },
    },
    [routes.applications.appeals.noticeRead]: {
      meta: {
        fields: noticeReadFields,
      },
      on: {
        CONTINUE: routes.applications.appeals.review,
      },
    },
    [routes.applications.appeals.review]: {
      on: {
        CONTINUE: routes.applications.appeals.haveDocuments,
        // this should be the same page as the one the start page routes to:
        EDIT: routes.applications.appeals.reason,
      },
    },
    [routes.applications.appeals.haveDocuments]: {
      on: {
        CONTINUE: [
          {
            cond: "haveAppealDocumentsToUpload",
            target: routes.applications.appeals.upload,
          },
          {
            target: routes.applications.status.claim,
          },
        ],
      },
    },
    [routes.applications.appeals.upload]: {
      on: {
        CONTINUE: routes.applications.status.claim,
      },
    },
  },
};
