import ApiResourceCollection from "src/models/ApiResourceCollection";
import { ErrorsLogic } from "./useErrorsLogic";
import Payment from "src/models/Payment";
import PaymentsApi from "src/api/PaymentsApi";
import useCollectionState from "./useCollectionState";
import { useState } from "react";

const usePaymentsLogic = ({ errorsLogic }: { errorsLogic: ErrorsLogic }) => {
  const paymentsApi = new PaymentsApi();
  const { collection: payments, setCollection: setPayments } =
    useCollectionState(new ApiResourceCollection<Payment>("payment_id"));
  const [currentAbsenceId, setCurrentAbsenceId] = useState<string>();
  /**
   * Check if payments are loading for a claim
   */
  const [isLoadingPayments, setIsLoadingPayments] = useState<boolean>();

  /**
   * Load payments for a single claim. Note that if there is already a payment being loaded then
   * this function will immediately return undefined.
   */
  const loadPayments = async (absenceId: string) => {
    if (isLoadingPayments) return;
    if (absenceId === currentAbsenceId) return;
    setCurrentAbsenceId(absenceId);

    setIsLoadingPayments(true);
    errorsLogic.clearErrors();
    try {
      const { payments } = await paymentsApi.getPayments(absenceId);
      setPayments(payments);
    } catch (error) {
      errorsLogic.catchError(error);
    } finally {
      setIsLoadingPayments(false);
    }
  };

  return {
    payments,
    loadPayments,
    isLoadingPayments,
    currentAbsenceId,
  };
};
export default usePaymentsLogic;
export type PaymentsLogic = ReturnType<typeof usePaymentsLogic>;
