import React, { useEffect, useMemo, useState } from "react";
import useFormState, { FormStateBody } from "src/hooks/useFormState";
import withBenefitsApplication, {
  WithBenefitsApplicationProps,
} from "src/hoc/withBenefitsApplication";

import AddressModel from "src/models/Address";
import { Addresses } from "src/models/Experian";
import ApplicationPagePrepopulated from "src/features/universal-profiles/application-page-prepopulated";
import Button from "src/components/core/Button";
import ConditionalContent from "src/components/ConditionalContent";
import FieldsetAddress from "src/components/FieldsetAddress";
import FormLabel from "src/components/core/FormLabel";
import InputChoiceGroup from "src/components/core/InputChoiceGroup";
import LocalStorageItem from "src/utils/localStorageItem";
import QuestionPage from "src/components/QuestionPage";
import formatAddress from "src/utils/formatAddress";
import { isFeatureEnabled } from "src/services/featureFlags";
import { pick } from "lodash";
import routes from "src/routes";
import useFunctionalInputProps from "src/hooks/useFunctionalInputProps";
import { useTranslation } from "src/locales/i18n";

export const fields = [
  "claim.has_mailing_address",
  "claim.residential_address.line_1",
  "claim.residential_address.line_2",
  "claim.residential_address.city",
  "claim.residential_address.state",
  "claim.residential_address.zip",
  // Include `mailing_address` so validation error shows for completely empty mailing address.
  // We don't need this for `residential_address` since that defaults to a blank object, rather than null.
  "claim.mailing_address",
  "claim.mailing_address.line_1",
  "claim.mailing_address.line_2",
  "claim.mailing_address.city",
  "claim.mailing_address.sate",
  "claim.mailing_address.zip",
];

export const Address = (props: WithBenefitsApplicationProps) => {
  const { appLogic, claim } = props;
  const { t } = useTranslation();
  const formStateKey = "formState";

  const usedProfileData =
    isFeatureEnabled("enableUniversalProfileIDV") &&
    !!claim.fields_to_use_from_user_profile?.includes("addresses");

  const formStateItem = useMemo(
    () => new LocalStorageItem(formStateKey, ""),
    [formStateKey]
  );

  // Helper function to parse form state safely
  function parseFormState(
    savedState: string | null
  ): FormStateBody | undefined {
    try {
      return savedState ? JSON.parse(savedState) : undefined;
    } catch (e) {
      console.error("Failed to parse saved form state:", e);
      return undefined;
    }
  }

  const { formState, getField, updateFields, clearField } = useFormState(
    isFeatureEnabled("enableAddressValidation")
      ? parseFormState(formStateItem.get()) || pick(props, fields).claim
      : pick(props, fields).claim
  );

  const [isClearing, setIsClearing] = useState(false);

  const clearFormState = () => {
    setIsClearing(true);
    formStateItem.remove(); // Clear from localStorage
  };

  // For the IDV Pilot, back links need to be made explicit.
  const [backButtonLink, setBackButtonLink] = useState<string>(
    `${routes.applications.phoneNumber}?claim_id=${claim.application_id}` // For the IDV Pilot, set the default value
  );
  const [submitButtonLabel, setSubmitButtonLabel] = useState<string>(
    t("pages.claimsAddress.validateAddress")
  );
  const [isReadyToSelectAddress, setIsReadyToSelectAddress] = useState(false);
  const [isAddressVerified, setIsAddressVerified] = useState(false);
  const [availableAddresses, setAvailableAddresses] = useState<Addresses[]>([]);
  const [selectedAddressKey, setSelectedAddressKey] = useState<string>("");
  const [useDefaultAddress, setUseDefaultAddress] = useState<boolean>(false);
  const [unvalidatedAddress, setUnvalidatedAddress] = useState<string>("");

  const { has_mailing_address } = formState;
  const experianAddressSuggestionThreshold = process.env
    .experianAddressSuggestionThreshold
    ? Number(process.env.experianAddressSuggestionThreshold)
    : 8;

  useEffect(() => {
    const existingMailingAddress = formState.mailing_address;
    if (formState.has_mailing_address && !existingMailingAddress) {
      updateFields({ mailing_address: {} });
    }
  }, [formState, updateFields]);

  const handleSave = async () => {
    if (isFeatureEnabled("enableAddressValidation")) {
      if (!isClearing) {
        formStateItem.set(JSON.stringify(formState));
      }
      if (useDefaultAddress) {
        // User wants to continue with unvalidated address
        clearFormState();
        appLogic.benefitsApplications.update(claim.application_id, formState);
      } else if (selectedAddressKey) {
        // Uers wants to continue with address validated using Experian

        const addressFormatResponse =
          await appLogic.benefitsApplications.addressFormat(selectedAddressKey);

        if (addressFormatResponse) {
          const formattedAddress = addressFormatResponse.address;

          if (has_mailing_address) {
            formState.is_mailing_address_validated = true;
            updateFields({
              "mailing_address.line_1": formattedAddress.address_line_1,
              "mailing_address.line_2": formattedAddress.address_line_2,
              "mailing_address.city": formattedAddress.locality,
              "mailing_address.state": formattedAddress.region,
              "mailing_address.zip": formattedAddress.postal_code,
            });
          } else {
            formState.is_residential_address_validated = true;
            updateFields({
              "residential_address.line_1": formattedAddress.address_line_1,
              "residential_address.line_2": formattedAddress.address_line_2,
              "residential_address.city": formattedAddress.locality,
              "residential_address.state": formattedAddress.region,
              "residential_address.zip": formattedAddress.postal_code,
            });
          }

          setIsAddressVerified(true);
          clearFormState();
          appLogic.benefitsApplications.update(claim.application_id, formState);
        }
      } else if (!isAddressVerified) {
        if (isFeatureEnabled("enableMmgIDV")) {
          // For the IDV pilot we're saving user edits to evaluate if we need to move the applicant to unverified status
          // Edits from Experian Address validation are ignored for the pilot
          appLogic.benefitsApplications.update(
            claim.application_id,
            formState,
            false
          );
        }
        // Perform address fields validation
        const issues = appLogic.benefitsApplications.validateAddressFields(
          formState.residential_address,
          formState.mailing_address,
          formState.has_mailing_address
        );

        if (issues?.length === 0) {
          // User either mailing or residential address for Experian address search
          // mailing address has priority over residential address
          let address;
          if (formState.has_mailing_address && formState.mailing_address) {
            address = formState.mailing_address;
          } else {
            address = formState.residential_address;
          }

          const { line_1, line_2, city, state, zip } = address;

          // This is user entered address, which is not yet validated and
          // provided as one of the choices to select as address
          setUnvalidatedAddress(
            formatAddress(line_1, line_2, city, state, zip)
          );

          // Call address search API
          const addressResponse =
            await appLogic.benefitsApplications.addressSearch(
              line_1,
              line_2,
              city,
              state,
              zip
            );

          if (addressResponse?.addresses) {
            const addressCount = addressResponse.addresses.length;

            if (addressCount > 0) {
              setSubmitButtonLabel(
                t("pages.claimsAddress.submitButtonLabelMultipleResults")
              );

              if (addressCount <= experianAddressSuggestionThreshold) {
                setAvailableAddresses(addressResponse.addresses);

                // By default select the single address if there's only one suggestion
                if (addressCount === 1) {
                  handleAddressSelection(
                    addressResponse.addresses[0].global_address_key
                  );
                }
              } else {
                // Address count exceeds threshold
                setAvailableAddresses([]);
              }
            } else {
              // Handle cases where address count is 0
              setSubmitButtonLabel(
                t("pages.claimsAddress.submitButtonLabelZeroResults")
              );
              setUseDefaultAddress(true);
              setAvailableAddresses([]);
            }
          } else {
            // Handle cases where addressResponse is undefined or addresses is not present
            setAvailableAddresses([]);
            console.error("No addresses returned from the search.");
          }

          setIsReadyToSelectAddress(
            (addressResponse && addressResponse.ready_to_select_addresses) ||
              false
          );
          setBackButtonLink(
            `${routes.applications.address}?claim_id=${claim.application_id}`
          );
        }
      }
    } else {
      appLogic.benefitsApplications.update(claim.application_id, formState);
    }
  };

  const getFunctionalInputProps = useFunctionalInputProps({
    errors: appLogic.errors,
    formState,
    updateFields,
  });

  const residentialAddressProps = getFunctionalInputProps(
    "residential_address"
  );
  if (!residentialAddressProps.value) {
    residentialAddressProps.value = new AddressModel({});
  }

  const mailingAddressProps = getFunctionalInputProps("mailing_address");
  if (!mailingAddressProps.value) {
    mailingAddressProps.value = new AddressModel({});
  }

  const handleAddressSelection = (key: string) => {
    setSelectedAddressKey(key);
    setUseDefaultAddress(false);
  };

  const handleDefaultAddressSelection = () => {
    setSelectedAddressKey("default_address");
    setUseDefaultAddress(true);
    setIsAddressVerified(true); // Default address doesn't need to validate using Experian
  };

  const handleRefineSearchClick = () => {
    setUseDefaultAddress(false);
    setIsReadyToSelectAddress(false);
    setIsAddressVerified(false);
    setSubmitButtonLabel(t("pages.claimsAddress.validateAddress"));
    setBackButtonLink(
      `${routes.applications.phoneNumber}?claim_id=${claim.application_id}`
    );
  };

  return isFeatureEnabled("enableAddressValidation") ? (
    <QuestionPage
      title={t("pages.claimsAddress.addressSuggestionTitle")}
      onSave={handleSave}
      continueButtonLabel={submitButtonLabel}
      backButtonLink={backButtonLink}
      alertBanner={usedProfileData && <ApplicationPagePrepopulated />}
    >
      {!isReadyToSelectAddress ? (
        <React.Fragment>
          <FieldsetAddress
            errors={appLogic.errors}
            label={t("pages.claimsAddress.sectionLabel")}
            hint={t("pages.claimsAddress.hint")}
            {...residentialAddressProps}
          />
          <InputChoiceGroup
            {...getFunctionalInputProps("has_mailing_address")}
            choices={[
              {
                checked: has_mailing_address === false,
                label: t("pages.claimsAddress.choiceYes"),
                value: "false",
              },
              {
                checked: has_mailing_address === true,
                label: t("pages.claimsAddress.choiceNo"),
                value: "true",
              },
            ]}
            label={t("pages.claimsAddress.hasMailingAddressLabel")}
            hint={t("pages.claimsAddress.hasMailingAddressHint")}
            type="radio"
          />
          <ConditionalContent
            fieldNamesClearedWhenHidden={["mailing_address"]}
            getField={getField}
            clearField={clearField}
            updateFields={updateFields}
            visible={has_mailing_address}
          >
            <FieldsetAddress
              errors={appLogic.errors}
              label={t("pages.claimsAddress.mailingAddressLabel")}
              hint={t("pages.claimsAddress.mailingAddressHint")}
              addressType="mailing"
              {...mailingAddressProps}
            />
          </ConditionalContent>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {availableAddresses.length > 0 && (
            <React.Fragment>
              <FormLabel
                component="legend"
                hint={t("pages.claimsAddress.cannotVerifyAddressHint")}
              >
                {t("pages.claimsAddress.cannotVerifyAddressLabel")}
              </FormLabel>
              <FormLabel component="legend">
                {t("pages.claimsAddress.addressSelectionQuestionLabel")}
              </FormLabel>

              {/* If only one address is available, automatically select the radio button
                  Otherwise, show a list of available addresses to choose from */}
              {availableAddresses.length === 1 ? (
                <InputChoiceGroup
                  name="selectedAddress"
                  choices={[
                    {
                      label: availableAddresses[0].address,
                      value: availableAddresses[0].global_address_key,
                      checked: true,
                    },
                  ]}
                  label={t(
                    "pages.claimsAddress.addressSelectionVerifiedAddressLabel"
                  )}
                  type="radio"
                  smallLabel
                />
              ) : (
                <InputChoiceGroup
                  name="selectedAddress"
                  choices={availableAddresses.map((address) => ({
                    label: address.address,
                    value: address.global_address_key,
                    checked: selectedAddressKey === address.global_address_key,
                  }))}
                  label={t(
                    "pages.claimsAddress.addressSelectionVerifiedAddressLabel"
                  )}
                  onChange={(e) => handleAddressSelection(e.target.value)}
                  type="radio"
                  smallLabel
                />
              )}
            </React.Fragment>
          )}

          {availableAddresses.length === 0 ||
          availableAddresses.length > experianAddressSuggestionThreshold ? (
            // If address count is 0 or exceeds threshold, show the default address
            // and option to edit the address
            <React.Fragment>
              <FormLabel
                component="legend"
                hint={t("pages.claimsAddress.cannotVerifyAddressHint")}
              >
                {t("pages.claimsAddress.cannotVerifyNoMatchAddressLabel")}
              </FormLabel>
              <FormLabel component="legend" hint={unvalidatedAddress} small>
                {t(
                  "pages.claimsAddress.addressSelectionNoResultUserProvidedAddressLabel"
                )}
              </FormLabel>
              <Button
                className="margin-top-4"
                type="submit"
                onClick={handleRefineSearchClick}
              >
                Edit your address
              </Button>
              <br></br>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <br></br>
              <InputChoiceGroup
                name="selectedAddress"
                choices={[
                  {
                    label: unvalidatedAddress,
                    value: "default_address",
                    checked: selectedAddressKey === "default_address",
                    hint: t(
                      "pages.claimsAddress.addressSelectionUserProvidedAddressLabelHint"
                    ),
                  },
                ]}
                label={t(
                  "pages.claimsAddress.addressSelectionUserProvidedAddressLabel"
                )}
                onChange={(_e) => handleDefaultAddressSelection()}
                type="radio"
                smallLabel
              />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </QuestionPage>
  ) : (
    <QuestionPage title={t("pages.claimsAddress.title")} onSave={handleSave}>
      <FieldsetAddress
        errors={appLogic.errors}
        label={t("pages.claimsAddress.sectionLabel")}
        hint={t("pages.claimsAddress.hint")}
        {...residentialAddressProps}
      />
      <InputChoiceGroup
        {...getFunctionalInputProps("has_mailing_address")}
        choices={[
          {
            checked: has_mailing_address === false,
            label: t("pages.claimsAddress.choiceYes"),
            value: "false",
          },
          {
            checked: has_mailing_address === true,
            label: t("pages.claimsAddress.choiceNo"),
            value: "true",
          },
        ]}
        label={t("pages.claimsAddress.hasMailingAddressLabel")}
        hint={t("pages.claimsAddress.hasMailingAddressHint")}
        type="radio"
      />
      <ConditionalContent
        fieldNamesClearedWhenHidden={["mailing_address"]}
        getField={getField}
        clearField={clearField}
        updateFields={updateFields}
        visible={has_mailing_address}
      >
        <FieldsetAddress
          errors={appLogic.errors}
          label={t("pages.claimsAddress.mailingAddressLabel")}
          hint={t("pages.claimsAddress.mailingAddressHint")}
          addressType="mailing"
          {...mailingAddressProps}
        />
      </ConditionalContent>
    </QuestionPage>
  );
};

export default withBenefitsApplication(Address);
