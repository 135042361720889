import { isFeatureEnabled } from "src/services/featureFlags";
import isFunction from "./isFunction";
import { startCase } from "lodash";

export interface HeadersDict {
  [header: string]: string;
}

export type HeaderOptionValue = string | boolean;

type HeaderOptionValueFunction = () => HeaderOptionValue;

export type HeaderOption =
  | { name?: string; featureFlag: string }
  | { name: string; value: HeaderOptionValue | HeaderOptionValueFunction };

export function resolveHeaderOptions(headerOpts: HeaderOption[]): HeadersDict {
  const headers: HeadersDict = {};

  for (const headerOpt of headerOpts) {
    const [headerName, headerValue] = resolveHeaderOption(headerOpt);
    headers[headerName] = headerValue;
  }

  return headers;
}

export function resolveHeaderOption(headerOpt: HeaderOption): [string, string] {
  let headerName: string;
  let headerValue: string;

  if ("featureFlag" in headerOpt) {
    headerName = headerOpt.name || featureFlagHeaderName(headerOpt.featureFlag);

    headerValue = isFeatureEnabled(headerOpt.featureFlag).toString();
  } else {
    // it has to be a "name, value" header option
    headerName = headerOpt.name;

    if (isFunction<HeaderOptionValueFunction>(headerOpt.value)) {
      headerValue = headerOpt.value().toString();
    } else {
      headerValue = headerOpt.value.toString();
    }
  }

  return [headerName, headerValue];
}

export function headerCase(str: string): string {
  return startCase(str).replace(/ /g, "-");
}

export const FEATURE_FLAG_HEADER_PREFIX = "X-FF-";

export function featureFlagHeaderName(featureFlagName: string): string {
  const headerCaseName = headerCase(featureFlagName);
  return `${FEATURE_FLAG_HEADER_PREFIX}${headerCaseName}`;
}
