import AlertBar from "src/components/core/AlertBar";
import AuthNav from "src/components/AuthNav";
import BrandBanner from "./BrandBanner";
import { LocalizeProps } from "types/common";
import React from "react";
import SiteLogo from "./SiteLogo";
import { Trans } from "react-i18next";
import User from "src/models/User";
import logo from "@massds/mayflower-assets/static/images/logo/stateseal.png";
import routes from "src/routes";
import tracker from "src/services/tracker";
import { useTranslation } from "src/locales/i18n";

interface HeaderProps {
  user?: User;
  updateUser?: (
    user_id: User["user_id"],
    patchData: Partial<User>
  ) => Promise<User | undefined>;
  onLogout: React.MouseEventHandler<HTMLButtonElement>;
  showUpcomingMaintenanceAlertBar: boolean;
  maintenanceStartTime: string | null;
  maintenanceEndTime: string | null;
}

/**
 * Global page header, displayed at the top of every page.
 */
const Header = (props: HeaderProps) => {
  const { t } = useTranslation();

  const [selectedLanguage, setSelectedLanguage] = React.useState(
    {} as LocalizeProps
  );

  React.useEffect(() => {
    window.Localize?.on("setLanguage", (data: LocalizeProps) => {
      setSelectedLanguage(data);
    });
  }, []);

  React.useEffect(() => {
    if (
      Object.keys(selectedLanguage).length > 0 &&
      selectedLanguage.from !== "source" &&
      selectedLanguage.from !== selectedLanguage.to
    ) {
      tracker.trackEvent("User changed language", {
        user_id: props.user?.user_id ?? "",
        url: window.location.href,
        language_from: selectedLanguage.from,
        language_to: selectedLanguage.to,
      });
    }
  }, [selectedLanguage, props]);

  const {
    showUpcomingMaintenanceAlertBar,
    maintenanceStartTime,
    maintenanceEndTime,
  } = props;

  return (
    <React.Fragment>
      <a href="#main" className="usa-skipnav">
        {t("components.header.skipToContent")}
      </a>
      {showUpcomingMaintenanceAlertBar && maintenanceStartTime && (
        <AlertBar>
          <Trans
            i18nKey="components.maintenanceAlertBar.message"
            tOptions={{ context: maintenanceEndTime ? "withEndTime" : null }}
            values={{
              start: maintenanceStartTime,
              end: maintenanceEndTime,
            }}
          />
        </AlertBar>
      )}
      <BrandBanner />
      <div className="ma__header_slim">
        <div className="ma__header_slim__utility">
          <div className="ma__header_slim__utility-container ma__container">
            <AuthNav
              user={props.user}
              updateUser={props.updateUser}
              onLogout={props.onLogout}
            />
          </div>
        </div>
        <header className="ma__header_slim__header" id="header">
          <div className="ma__header_slim__header-container ma__container">
            <div className="ma__header_slim__logo">
              <SiteLogo
                url={routes.index}
                image={{
                  src: logo,
                  alt: t("components.siteLogo.sealAlt"),
                  width: 45,
                  height: 45,
                }}
                siteName={t("components.header.appTitle")}
              />
            </div>
          </div>
        </header>
      </div>
    </React.Fragment>
  );
};

export default Header;
