import { ISO8601Timestamp, ValuesOf } from "types/common";

import { PhoneWithoutExtension } from "./Phone";
import isBlank from "src/utils/isBlank";
import { merge } from "lodash";

export const AppealStatus = {
  closedDecisionChanged: "Closed - Claim Decision Changed",
  closedDecisionAffirmed: "Closed - Claim Decision Affirmed",
  triggerAppealStartedNotification: "Trigger Appeal Started Notification",
  openConductHearing: "Open - Conduct Hearing",
  openScheduleHearing: "Open - Schedule Hearing",
  addEmployerParticipantRole: "Add Employer Participant Role",
  openReconsideration: "Open - Reconsideration",
};

export type AppealStatusEnum = ValuesOf<typeof AppealStatus>;

class Appeal {
  appeal_id: string;
  appeal_phone_number: PhoneWithoutExtension | null;
  appeal_reason: string | null;
  appeal_representative_option: "Yes" | "No" | "Unknown" | null;
  appeal_status: AppealStatusEnum | null;
  computed_is_more_than_ten_days_past_decision: boolean;
  created_at: ISO8601Timestamp;
  fineos_absence_id: string;
  fineos_appeal_id: string | null;
  for_private_insurance: boolean | null;
  has_read_notices: boolean | null;
  had_preventing_circumstances: boolean | null;
  interpreter_language_requested: string | null;
  is_generated_from_extract: boolean;
  needs_interpreter: boolean | null;
  originally_decided_at: ISO8601Timestamp | null;
  originally_decided_at_reason_for_past_due: string | null;
  other_interpreter_language_requested: string | null;
  reason_for_not_reading_notices: string | null;
  submitted_at: ISO8601Timestamp | null;

  constructor(attrs: Partial<Appeal>) {
    merge(this, attrs);
  }

  get isSubmitted(): boolean {
    return !isBlank(this.submitted_at) || this.is_generated_from_extract;
  }

  get isClosed(): boolean {
    return (
      this.appeal_status === AppealStatus.closedDecisionAffirmed ||
      this.appeal_status === AppealStatus.closedDecisionChanged
    );
  }
}

export const findInProgressAppealByAbsenceId = (
  appeals: Appeal[],
  fineos_absence_id: string
): Appeal | undefined => {
  return appeals.find(
    (appeal) =>
      appeal.fineos_absence_id === fineos_absence_id && !appeal.isSubmitted
  );
};

export const findLatestAppealByAbsenceId = (
  appeals: Appeal[],
  fineos_absence_id: string
): Appeal | undefined => {
  const inProgressAppeal = findInProgressAppealByAbsenceId(
    appeals,
    fineos_absence_id
  );

  if (inProgressAppeal) {
    return inProgressAppeal;
  }

  const filteredAppeals = appeals.filter(
    (appeal) => appeal.fineos_absence_id === fineos_absence_id
  );

  return filteredAppeals.length === 0
    ? undefined
    : filteredAppeals[filteredAppeals.length - 1];
};

export default Appeal;
