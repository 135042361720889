import { Issue } from "src/errors";
import { compact } from "lodash";

/**
 * Filters warnings based on field names or applicable rules.
 * Supports validating fields in the middle of a flow.
 * See `docs/portal/error-handling.md`
 */
function getRelevantIssues(
  warnings: Issue[] = [],
  pages: Array<{
    meta?: { applicableRules?: string[]; fields?: string[] };
    on?: unknown; // doesn't technically matter, just checking that it's a routing state machine node
  }> = []
) {
  let relevantWarnings = warnings;

  if (warnings.length && pages.length) {
    const applicableRules = compact(
      pages.map((page) => page.meta?.applicableRules).flat()
    );

    // Get the array of fields from each page, and output a single array of all those fields
    // [["firstName", "last_name"], ["ssn"]] => ["firstName", "last_name", "ssn"]
    let fields = pages.map((page) => page.meta?.fields).flat();

    // Remove falsy values, which may occur if a page didn't include a set of fields,
    // then remove the model prefix:
    fields = compact(fields).map((field) => {
      return (
        // Fields are matched using regex
        field
          // Our pages export fields prefixed with the model, but the warnings
          // won't include that prefix, so we trim it here:
          .replace(/^claim./, "")
          .replace(/^change_request./, "")
          .replace(/^exemptionRequest./, "")
          // support wild card in array field names
          // e.g. work_pattern.work_pattern_days[*].minutes
          .replace(/\*/g, "\\d+")
          // escape brackets for support in regex
          // eslint-disable-next-line no-useless-escape
          .replace(/(?=[\[\]])/g, "\\")
          // Prepend entire field with ^ and append with $
          // to enforce exact match. Without this
          // leave_details.continuous_leave_periods would match
          // both leave_details.continuous_leave_periods and
          // leave_details.continuous_leave_periods[0].start_date
          .replace(/(.*)/, "^$&$")
      );
    });

    relevantWarnings = warnings.filter(({ field = "", rule = "" }) => {
      return (
        applicableRules.includes(rule) ||
        fields.some((f) => f && !!field.match(f))
      );
    });
  }

  // When we have a disallow_hybrid_intermittent_leave issue, then we don't
  // show intermittent leave field-level issues since those are redundant
  // and likely confusing if shown
  if (
    relevantWarnings.find(
      (issue) => issue.rule === "disallow_hybrid_intermittent_leave"
    )
  ) {
    relevantWarnings = relevantWarnings.filter(({ field = "" }) => {
      return !field.startsWith("leave_details.intermittent_leave_periods[0]");
    });
  }

  return relevantWarnings;
}

export default getRelevantIssues;
