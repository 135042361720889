import withBenefitsApplication, {
  WithBenefitsApplicationProps,
} from "src/hoc/withBenefitsApplication";

import ApplicationPagePrepopulated from "src/features/universal-profiles/application-page-prepopulated";
import Fieldset from "src/components/core/Fieldset";
import FormLabel from "src/components/core/FormLabel";
import InputText from "src/components/core/InputText";
import QuestionPage from "src/components/QuestionPage";
import React from "react";
import { isFeatureEnabled } from "src/services/featureFlags";
import { pick } from "lodash";
import routes from "src/routes";
import useFormState from "src/hooks/useFormState";
import useFunctionalInputProps from "src/hooks/useFunctionalInputProps";
import { useTranslation } from "src/locales/i18n";

export const fields = [
  "claim.first_name",
  "claim.middle_name",
  "claim.last_name",
];

export const Name = (props: WithBenefitsApplicationProps) => {
  const { appLogic, claim } = props;
  const { t } = useTranslation();

  const usedProfileData =
    isFeatureEnabled("enableUniversalProfileIDV") &&
    (!!claim.fields_to_use_from_user_profile?.includes("firstname") ||
      !!claim.fields_to_use_from_user_profile?.includes("lastname"));

  const { formState, updateFields } = useFormState(pick(props, fields).claim);

  const handleSave = () =>
    appLogic.benefitsApplications.update(claim.application_id, formState);

  const getFunctionalInputProps = useFunctionalInputProps({
    errors: appLogic.errors,
    formState,
    updateFields,
  });

  return (
    <QuestionPage
      title={t("pages.claimsName.title")}
      onSave={handleSave}
      backButtonLink={`${routes.applications.checklist}?claim_id=${claim.application_id}`} // For the IDV Pilot, back links need to be made explicit.
      alertBanner={usedProfileData && <ApplicationPagePrepopulated />}
    >
      <Fieldset>
        <FormLabel component="legend" hint={t("pages.claimsName.lead")}>
          {t("pages.claimsName.sectionLabel")}
        </FormLabel>
        <InputText
          {...getFunctionalInputProps("first_name")}
          label={t("pages.claimsName.firstNameLabel")}
          smallLabel
        />
        <InputText
          {...getFunctionalInputProps("middle_name")}
          label={t("pages.claimsName.middleNameLabel")}
          optionalText={t("components.form.optional")}
          smallLabel
        />
        <InputText
          {...getFunctionalInputProps("last_name")}
          label={t("pages.claimsName.lastNameLabel")}
          smallLabel
        />
      </Fieldset>
    </QuestionPage>
  );
};

export default withBenefitsApplication(Name);
