import { ValuesOf } from "types/common";

/**
 * Enums for an absence case's `leave_details.reason` field. Claimant Portal sends some of these,
 * and Leave Admin portal needs to display any that a claimant can apply to via any channel.
 * @enum {string}
 */
const LeaveReason = {
  activeDutyFamily: "Military Exigency Family",
  bonding: "Child Bonding",
  care: "Care for a Family Member",
  medical: "Serious Health Condition - Employee",
  pregnancy: "Pregnancy/Maternity",
  serviceMemberFamily: "Military Caregiver",
} as const;

export type LeaveReasonKeys = keyof typeof LeaveReason;

export type LeaveReasonType = ValuesOf<typeof LeaveReason>;

export default LeaveReason;
