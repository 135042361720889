import BaseApi, {
  createRequestUrl,
  fetchErrorToNetworkError,
  getAuthorizationHeader,
  handleNotOkResponse,
} from "./BaseApi";

import { UserLeaveAdministrator } from "src/models/User";
import routes from "src/routes";

export type LeaveAdminsSearchRequest =
  | { employer_id: string }
  | { user_id: string };

export interface LeaveAdminsRemoveRequest {
  user_leave_administrator_id: string;
}

export type LeaveAdminsAddRequest = { employer_id: string } & {
  email_address: string;
};

export default class LeaveAdminsApi extends BaseApi {
  get basePath() {
    return routes.api.leaveAdmins;
  }

  get namespace() {
    return "leaveAdmins";
  }

  add = async (request: LeaveAdminsAddRequest) => {
    await this.request<UserLeaveAdministrator[]>("POST", "add", request);
  };

  list = async (request: LeaveAdminsSearchRequest) => {
    const { data } = await this.request<UserLeaveAdministrator[]>(
      "POST",
      "search",
      request
    );

    return {
      leaveAdmins: data.map(
        (leaveAdmin) => new UserLeaveAdministrator(leaveAdmin)
      ),
    };
  };

  remove = async ({
    user_leave_administrator_id,
  }: LeaveAdminsRemoveRequest) => {
    await this.request<UserLeaveAdministrator[]>(
      "POST",
      `${user_leave_administrator_id}/deactivate`
    );
  };

  downloadCSV = async () => {
    const subPath = `/download-csv`;
    const method = "GET";
    const url = createRequestUrl(method, this.basePath, subPath);
    const authHeader = await getAuthorizationHeader();
    const csv_content_type = "text/csv";

    const headers = {
      ...authHeader,
      Accept: csv_content_type,
      "Content-Type": csv_content_type,
    };

    let blob: Blob, response: Response;

    try {
      response = await fetch(url, { headers, method });
      blob = await response.blob();
    } catch (error) {
      throw fetchErrorToNetworkError(error);
    }

    if (!response.ok) {
      handleNotOkResponse(response, [], this.namespace);
    }

    return blob;
  };
}
