import tracker from "src/services/tracker";

interface CompareAndLogDifferencesProps {
  frontendValue?: string | null;
  backendValue?: string | null;
  relevantTicketID: string;
  functionName: string;
  modelName: string;
}

export const compareAndLogDifferences = ({
  frontendValue,
  backendValue,
  relevantTicketID,
  functionName,
  modelName,
}: CompareAndLogDifferencesProps) => {
  const logEntryName = relevantTicketID;

  if (frontendValue === backendValue) {
    tracker.trackEvent(logEntryName, {
      matches: "true",
    });
  }

  if (frontendValue !== backendValue) {
    tracker.trackEvent(logEntryName, {
      matches: "false",
      frontendValue: frontendValue || "",
      backendValue: backendValue || "",
      functionName,
      modelName,
    });
  }
};
